import React from 'react';
import { Grid } from '@mui/material';
import { dropAlimentacao } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import VirtualDrop from 'components/VirtualDrop';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('referencia') && (
        <Grid item xs={12}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
      )}
      {checkDisplay('marca') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="marca"
            control={control}
            label="Marca"
            options={drops?.Marca}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('modelo') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="modelo"
            control={control}
            label="Modelo"
            options={drops?.Modelo}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('patrimonio') && (
        <Grid item xs={12}>
          <Input name="patrimonio" control={control} label="Patrimônio" />
        </Grid>
      )}
      {checkDisplay('alimentacao') && (
        <Grid item xs={12}>
          <Dropdown
            name="alimentacao"
            control={control}
            label="Alimentação"
            options={dropAlimentacao}
            multiple
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
