import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import {
  AccountBalance,
  Description,
  Equalizer,
  Extension,
  Link,
  LocalAtm,
  PrecisionManufacturing,
  Timeline,
} from '@mui/icons-material';
import { decryptURL } from 'utils/functions';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog, useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';
import CustomTabs from './Tabs';
import localeText from 'utils/localeText';

const DocumentoItem = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const { hash } = useParams();
  const { closeDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const {
    documento,
    getLoading,
    postLoading,
    getDocumento,
    postDocumento,
    postDocumentoItemImposto,
    postDocumentoItemProcesso,
    postDocumentoItemEstrutura,
  } = useContext(DocumentosContext);
  const decrypt = decryptURL(hash);
  const item = documento?.DocumentoItems?.find((f) => f?.id === decrypt?.id);
  const disableActions =
    documento?.conferido === 'SIM' || documento?.situacao !== 'PENDENTE';
  const gridProps = {
    hideFooter: true,
    autoHeight: true,
    density: 'compact',
    disableSelectionOnClick: true,
    disableColumnMenu: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
    getRowHeight: () => 'auto',
    loading: postLoading,
    localeText,
    components: { LoadingOverlay: LinearProgress },
  };

  const loadDocumento = () => getDocumento(decrypt?.documento_id);

  useEffect(() => {
    if (!Boolean(decrypt) || !Boolean(item)) {
      navigate(-1);
    }
  }, []);

  const onSubmit = (data) =>
    postDocumento({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
        closeModal();
      },
    });

  const onDelete = (params) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params,
            cb: () => {
              loadDocumento();
              closeDialog();
              closeModal();
            },
          })
        }
      />
    );

  const onSubmitImposto = (data) =>
    postDocumentoItemImposto({ data, cb: loadDocumento });

  const onSubmitProcesso = (data) =>
    postDocumentoItemProcesso({ data, cb: loadDocumento });

  const onSubmitEstrutura = (data) =>
    postDocumentoItemEstrutura({ data, cb: loadDocumento });

  const tabs = [
    {
      value: 0,
      key: 'Item',
      label: 'Item',
      icon: <Description />,
    },
    {
      value: 1,
      key: 'Estrutura',
      label: 'Estrutura',
      icon: <Extension />,
      show: item?.Produto?.Celinha?.estrutura === 'SIM',
    },
    {
      value: 2,
      key: 'Processos',
      label: 'Processos',
      icon: <PrecisionManufacturing />,
      show: item?.Produto?.Celinha?.processo === 'SIM',
    },
    {
      value: 3,
      key: 'Tributos',
      label: 'Tributos',
      icon: <LocalAtm />,
    },
    {
      value: 4,
      key: 'Margem de Contribuição',
      label: 'Margem de Contribuição',
      icon: <AccountBalance />,
    },
    {
      value: 5,
      key: 'Vínculos',
      label: 'Vínculos',
      icon: <Link />,
    },
    {
      value: 6,
      key: 'Variações',
      label: 'Variações',
      icon: <Equalizer />,
      show: item?.Produto?.variacao === 'SIM',
    },
    {
      value: 7,
      key: 'Movimentações',
      label: 'Movimentações',
      icon: <Timeline />,
    },
  ];

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={item?.descricao || ''} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(_, tab) => setTab(tab)}
              TabIndicatorProps={styles?.indicatorTabs}
              sx={styles?.tabs}
            >
              {tabs?.map(
                ({ show = true, key, ...t }) => show && <Tab key={key} {...t} />
              )}
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CustomTabs
            tab={tab}
            item={item}
            drops={drops}
            onSubmit={onSubmit}
            onDelete={onDelete}
            documento={documento}
            loading={postLoading}
            gridProps={gridProps}
            getLoading={getLoading}
            disableActions={disableActions}
            onSubmitImposto={onSubmitImposto}
            onSubmitProcesso={onSubmitProcesso}
            onSubmitEstrutura={onSubmitEstrutura}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DocumentoItem;
