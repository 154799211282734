import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { EquipamentosContext } from 'contexts/EquipamentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { dropAlimentacao } from 'utils/drops';
import { useDialog } from 'components/Modals';
import VirtualDrop from 'components/VirtualDrop';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const EquipamentoModal = ({ equipamento, callback }) => {
  const defaultValues = {
    descricao: '',
    referencia: '',
    patrimonio: '',
    alimentacao: null,
    cemarca_id: null,
    modelo_id: null,
  };
  const { closeDialog } = useDialog();
  const { drops, getDrops } = useContext(DropsContext);
  const { postEquipamento, postLoading } = useContext(EquipamentosContext);

  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (equipamento) {
      if (Boolean(equipamento?.descricao)) {
        setValue('descricao', equipamento?.descricao);
      }
      if (Boolean(equipamento?.referencia)) {
        setValue('referencia', equipamento?.referencia);
      }
      if (Boolean(equipamento?.patrimonio)) {
        setValue('patrimonio', equipamento?.patrimonio);
      }
      if (Boolean(equipamento?.alimentacao)) {
        setValue('alimentacao', equipamento?.alimentacao);
      }
      if (Boolean(equipamento?.cemarca_id)) {
        setValue('cemarca_id', equipamento?.cemarca_id);
      }
      if (Boolean(equipamento?.modelo_id)) {
        setValue('modelo_id', equipamento?.modelo_id);
      }
    }
  }, [equipamento]);

  const onSubmit = (values) => {
    const data = { ...values, id: equipamento?.id };
    postEquipamento({
      data,
      cb: ({ id }) => {
        if (callback) {
          callback(id);
        }
        closeDialog();
        getDrops(['Equipamento']);
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações do Equipamento
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input name="descricao" control={control} label="Descrição" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input name="referencia" control={control} label="Referência" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input name="patrimonio" control={control} label="Patrimônio" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="alimentacao"
          control={control}
          label="Alimentação"
          options={dropAlimentacao}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="cemarca_id"
          control={control}
          label="Marca"
          options={drops?.Marca}
          onValueChange={() => resetField('modelo_id')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <VirtualDrop
          name="modelo_id"
          control={control}
          label="Modelo"
          options={drops?.Modelo?.filter(
            (m) => m?.cemarca_id === watch('cemarca_id')
          )}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default EquipamentoModal;
