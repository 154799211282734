import React, { useContext, useEffect, useState } from 'react';
import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  DayView,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  Resources,
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import {
  Edit,
  PlayArrow,
  Pause,
  Check,
  Extension,
  ExtensionOff,
  Undo,
} from '@mui/icons-material';
import { blueGrey, orange, teal } from '@mui/material/colors';
import { AgendaContext } from 'contexts/AgendaContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { GridContext } from 'contexts/GridContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import AgendamentoModal from 'components/Modals/AgendamentoModal';
import PeçasRetornoModal from './Modals/PeçasRetornoModal';
import PeçasEnvioModal from './Modals/PeçasEnvioModal';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Header from 'components/Header';
import Card from 'components/Card';
import moment from 'moment';
import styles from './styles';

const Agenda = () => {
  const titulo = 'Agenda';
  const [viewName, setViewName] = useState('Day');
  const [defaultCurrentDate, setDefaultCurrentDate] = useState(
    moment().format('YYYY-MM-DD')
  );
  const { openDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { postGrid, postLoading: gridLoading } = useContext(GridContext);
  const { getAgendas, loading, agendas } = useContext(AgendaContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);

  const resource = [
    {
      fieldName: 'status',
      title: 'Status',
      instances: [
        { id: 'NAO INICIADA', text: 'STATUS: NÃO INICIADA' },
        { id: 'EM ANDAMENTO', text: 'STATUS: EM ANDAMENTO' },
        { id: 'CONCLUIDA', text: 'STATUS: CONCLUÍDA' },
      ],
    },
    {
      fieldName: 'emitente',
      title: 'Emitente',
      instances: agendas?.map((a) => ({
        id: a?.emitente,
        text: `EMITENTE: ${a?.emitente}`,
        color: '#FFF',
      })),
    },
    {
      fieldName: 'destino',
      title: 'Destino',
      instances: agendas?.map((a) => ({
        id: a?.destino,
        text: `DESTINO: ${a?.destino}`,
        color: '#FFF',
      })),
    },
    {
      fieldName: 'tarefa',
      title: 'Atividades',
      instances: agendas?.map((a) => ({
        id: a?.tarefa,
        text: `ATIVIDADES: ${a?.tarefa}`,
        color: '#FFF',
      })),
    },
  ];

  const loadPage = (date = defaultCurrentDate) =>
    getAgendas({
      inicio: moment(date).startOf('month').format('YYYY-MM-DD'),
      fim: moment(date).endOf('month').format('YYYY-MM-DD'),
    });

  const checkStatus = (a) => {
    const apontamentos =
      a?.DocumentoItemProcesso?.DocumentoItemProcessoAponts || [];
    const aberto = apontamentos?.some(
      (a) => Boolean(a?.inicio) && !Boolean(a?.fim)
    );
    if (aberto) {
      return 'EM ANDAMENTO';
    }
    return a?.status;
  };
  const data = agendas?.map((a) => ({
    ...a,
    title: `${a?.cliente || ''} - ${a?.destino?.split(' ')[0] || ''} - ${
      a?.assunto || ''
    }`,
    startDate: a?.datahora,
    status: checkStatus(a),
  }));

  const getApontamento = (agenda) => {
    const iniciado =
      agenda?.DocumentoItemProcesso?.DocumentoItemProcessoAponts?.find((f) =>
        Boolean(f?.inicio && !f?.fim)
      );
    const Apontamentos = [
      {
        id: iniciado?.id,
        documento_item_processo_id: agenda?.DocumentoItemProcesso?.id,
        executante_id: iniciado?.executante_id || agenda?.userdest_id,
        inicio: iniciado?.inicio || moment().format('YYYY-MM-DD HH:mm:ss'),
        fim: iniciado?.inicio ? moment().format('YYYY-MM-DD HH:mm:ss') : null,
        users_id: user?.id,
      },
    ];
    return {
      Itens: [
        {
          id: agenda?.DocumentoItemProcesso?.documento_item_id,
          documento_id: agenda?.documento_id,
          Processos: [{ id: agenda?.DocumentoItemProcesso?.id, Apontamentos }],
        },
      ],
    };
  };

  const onSubmitStatus = (agenda, status = 'CONCLUIDA') => {
    const data = {
      id: agenda?.id,
      useremit_id: agenda?.useremit_id,
      userdest_id: agenda?.userdest_id,
      assunto: agenda?.assunto,
      status,
    };
    postGrid({ data, rotina: 'Tarefa', cb: loadPage });
  };

  const onChangeStatus = (agenda) => {
    if (agenda?.documento_item_processo_id) {
      if (agenda?.status === 'CONCLUIDA') {
        onSubmitStatus(agenda, 'NAO INICIADA');
      } else {
        postDocumento({ data: getApontamento(agenda), cb: loadPage });
      }
    } else {
      const data = {
        id: agenda?.id,
        useremit_id: agenda?.useremit_id,
        userdest_id: agenda?.userdest_id,
        assunto: agenda?.assunto,
        status:
          agenda?.status === 'NAO INICIADA' ? 'EM ANDAMENTO' : 'NAO INICIADA',
      };
      postGrid({ data, rotina: 'Tarefa', cb: loadPage });
    }
  };

  useEffect(() => {
    loadPage();
  }, []);

  const Content = ({ children, appointmentData, ...rest }) => (
    <AppointmentTooltip.Content {...rest} appointmentData={appointmentData}>
      <div style={styles.actionsContainer}>
        {appointmentData?.status !== 'CONCLUIDA' && (
          <IconButton
            size="small"
            onClick={() =>
              openDialog(
                <AgendamentoModal
                  agenda={appointmentData}
                  callback={() => loadPage()}
                />,
                'Editar Tarefa'
              )
            }
          >
            <Edit />
          </IconButton>
        )}
        <IconButton
          size="small"
          onClick={() => onChangeStatus(appointmentData)}
          disabled={postLoading || gridLoading}
        >
          {postLoading || gridLoading ? (
            <CircularProgress size={20} />
          ) : appointmentData?.status === 'EM ANDAMENTO' ? (
            <Pause />
          ) : appointmentData?.status === 'CONCLUIDA' ? (
            <Undo />
          ) : (
            <PlayArrow />
          )}
        </IconButton>
        {(appointmentData?.documento_item_processo_id
          ? appointmentData?.status === 'NAO INICIADA'
          : appointmentData?.status !== 'CONCLUIDA') && (
          <IconButton
            size="small"
            onClick={() => onSubmitStatus(appointmentData)}
            disabled={postLoading || gridLoading}
          >
            {postLoading || gridLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Check />
            )}
          </IconButton>
        )}
        {Boolean(appointmentData?.documento_id) &&
          Boolean(appointmentData?.documento_item_processo_id) &&
          appointmentData?.status !== 'CONCLUIDA' && (
            <IconButton
              size="small"
              onClick={() =>
                openDialog(
                  <PeçasEnvioModal
                    agenda={appointmentData}
                    callback={() => loadPage()}
                  />,
                  'Registrar envio de peças'
                )
              }
            >
              <Extension />
            </IconButton>
          )}
        {Boolean(appointmentData?.documento_id) &&
          Boolean(appointmentData?.documento_item_processo_id) &&
          appointmentData?.status !== 'CONCLUIDA' && (
            <IconButton
              size="small"
              onClick={() =>
                openDialog(
                  <PeçasRetornoModal
                    agenda={appointmentData}
                    callback={() => loadPage()}
                  />,
                  'Registrar retorno de peças'
                )
              }
            >
              <ExtensionOff />
            </IconButton>
          )}
      </div>
    </AppointmentTooltip.Content>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                openDialog(
                  <AgendamentoModal callback={() => loadPage()} />,
                  'Nova Tarefa'
                )
              }
            >
              Novo Agendamento
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Scheduler data={data} locale="pt-BR">
              <ViewState
                defaultCurrentDate={defaultCurrentDate}
                currentViewName={viewName}
                onCurrentViewNameChange={(name) => setViewName(name)}
                onCurrentDateChange={(a) =>
                  setDefaultCurrentDate((prev) => {
                    if (moment(prev).format('MM') !== moment(a).format('MM')) {
                      loadPage(moment(a).format('YYYY-MM-DD'));
                    }
                    return moment(a).format('YYYY-MM-DD');
                  })
                }
              />
              <DayView
                displayName="Dia"
                startDayHour={6}
                endDayHour={20}
                cellDuration={60}
              />
              <WeekView
                displayName="Semana"
                startDayHour={6}
                endDayHour={20}
                cellDuration={60}
              />
              <Toolbar />
              <ViewSwitcher />
              <DateNavigator />
              <TodayButton messages={{ today: 'Hoje' }} />
              <Appointments />
              <AppointmentTooltip showCloseButton contentComponent={Content} />
              <Resources
                data={resource}
                mainResourceName="status"
                palette={[teal, orange, blueGrey]}
              />
            </Scheduler>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Agenda;
