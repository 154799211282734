import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { findOnArray } from 'utils/functions';
import { DropsContext } from 'contexts/DropsContext';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const PagamentoModal = ({ item = {}, onSubmit }) => {
  const defaultValues = {
    ...item,
    dtvenc: item?.dtvenc || null,
    valor: item?.valor || '',
    forma_pagto_id: item?.forma_pagto_id || null,
    autenticacao: item?.autenticacao || '',
    forma_pagto: item?.forma_pagto || '',
    indpag: item?.indpag || null,
  };
  const { control, handleSubmit, setValue, watch } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);

  const modalidade = findOnArray(
    watch('forma_pagto_id'),
    drops?.FormaDePagamento,
    'modalidade'
  );
  const isCartao =
    modalidade === '03-CARTAO DE CREDITO' ||
    modalidade === '04-CARTAO DE DEBITO';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="dtvenc"
          control={control}
          label="Data de Vencimento"
          type="date"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask name="valor" control={control} label="Valor" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          onValueChange={(v) => {
            setValue('autenticacao', '');
            setValue('indpag', v?.indpag);
            setValue('forma_pagto', v?.label);
          }}
          options={drops?.FormaDePagamento?.filter(
            (f) =>
              f?.caixa === 'SIM' &&
              f?.modulo !== 'COMPRAS' &&
              f?.modulo !== 'CHEQUES'
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          name="autenticacao"
          control={control}
          label="SKU (Autenticação)"
          disabled={!isCartao}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default PagamentoModal;
