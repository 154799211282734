import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { AuthContext } from 'contexts/AuthContext';
import { DashboardContext } from 'contexts/DashboardContext';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Bar from './Charts/Bar';

const App = () => {
  const { user } = useContext(AuthContext);
  const { dashData, getLoading, getDashboard } = useContext(DashboardContext);

  useEffect(() => {
    getDashboard();
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Grid container spacing={5}>
        {!Boolean(user?.permissoes?.block_dashboard_pendentes) && (
          <Grid item xs={12} sm={6} display="flex">
            <Bar title="Serviços Pendentes" data={dashData?.pendentes} />
          </Grid>
        )}
        {!Boolean(user?.permissoes?.block_dashboard_pendentes_status) && (
          <Grid item xs={12} sm={6} display="flex">
            <Bar
              title="Serviços Pendentes - Por Status"
              data={dashData?.por_status}
            />
          </Grid>
        )}
        {!Boolean(user?.permissoes?.block_dashboard_pendentes_tecnico) && (
          <Grid item xs={12} sm={6} display="flex">
            <Bar
              title="Serviços Pendentes - Por Técnico"
              data={dashData?.por_tecnico}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default App;
