import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { dropTipoRateio } from 'utils/drops';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const AverageModal = ({ type = null, documento = {}, onSubmit }) => {
  const defaultValues = { type, percentual: '', valor: '' };
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });
  const { postLoading } = useContext(DocumentosContext);

  useEffect(() => {
    if (Boolean(watch('percentual'))) {
      const valor = (documento?.Totais?.preco * watch('percentual')) / 100;
      setValue('valor', valor);
    }
  }, [watch('percentual')]);

  const onConfirm = ({ type, valor }) => {
    const isMargem =
      type === 'internacionalizacao' ||
      type === 'fretend' ||
      type === 'segurond' ||
      type === 'impostond' ||
      type === 'comissaond';
    if (!Boolean(type)) {
      return toastWarning('Selecione o tipo');
    }

    let valor_lancado = valor;
    const Itens = documento?.DocumentoItems?.map((i, idx) => {
      const total = documento?.Totais?.preco;
      const preco = i?.preco;
      const quantidade = i?.quantidade;
      const average = parseFloat((preco * quantidade) / total);
      const average_total = valor * average;
      const value =
        idx + 1 === documento?.DocumentoItems?.length
          ? valor_lancado
          : parseFloat(average_total?.toFixed(2));
      valor_lancado -= value;
      if (isMargem) {
        return {
          id: i?.id,
          documento_id: i?.documento_id,
          Margem: {
            documento_item_id: i?.DocumentoItemMc?.documento_item_id,
            [type]: type === 'desconto_vlr' ? -value : value,
          },
        };
      } else {
        return {
          id: i?.id,
          documento_id: i?.documento_id,
          [type]: type === 'desconto_vlr' ? value * -1 : value,
        };
      }
    });
    onSubmit({ Itens });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações Gerais
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="type"
          control={control}
          label="Tipo de Divisão"
          options={dropTipoRateio}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask name="percentual" control={control} label="Percentual" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask name="valor" control={control} label="Valor" />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          color="primary"
          variant="outlined"
          loading={postLoading}
          onClick={handleSubmit(onConfirm)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default AverageModal;
