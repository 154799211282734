import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { dropBoolean } from 'utils/drops';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';
import VirtualDrop from 'components/VirtualDrop';
import moment from 'moment';

const Estrutura = ({
  onSubmit,
  onDelete,
  item = {},
  drops = [],
  gridProps = {},
  loading = false,
  onSubmitEstrutura,
  disableActions = false,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoItemEstrutura';
  const estrutura = item?.DocumentoItemEstruturas || [];

  const Modal = ({ estrutura }) => {
    const defaultValues = {
      id: estrutura?.id,
      documento_item_id: item?.id,
      componente_id: estrutura?.componente_id || null,
      operacao: estrutura?.operacao || '',
      sequencia: estrutura?.sequencia || '',
      quantidade: estrutura?.quantidade || '',
      perda: estrutura?.perda || '',
      volume: estrutura?.volume || '',
      retornavel: estrutura?.retornavel || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    const onConfirm = (values) => {
      const data = {
        Itens: [
          {
            id: item?.id,
            documento_id: item?.documento_id,
            Estruturas: [values],
          },
        ],
      };
      onSubmit(data);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <VirtualDrop
            name="componente_id"
            control={control}
            label="Componente"
            options={drops?.Produto}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="operacao" control={control} label="Operação" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="sequencia" control={control} label="Sequência" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="quantidade" control={control} label="Quantidade" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="perda" control={control} label="% Perda" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="volume" control={control} label="Volume" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="retornavel"
            control={control}
            label="Retornável"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const CustoCell = ({ value, row }) => {
    const custo = (value || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    const att =
      Boolean(row?.atualizado) && moment(row?.atualizado).isValid()
        ? moment(row?.atualizado).format('DD/MM/YYYY [às] HH:mm')
        : null;

    return (
      <Box flex={1} textAlign="end">
        <Typography variant="body2" fontWeight="bold">
          {custo}
        </Typography>
        {Boolean(att) && (
          <Typography variant="caption">Atualizado em {att}</Typography>
        )}
      </Box>
    );
  };

  const options = [
    {
      name: 'Adicionar Estrutura',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Estrutura'),
    },
    {
      name: 'Recarregar Estrutura',
      icon: 'refresh',
      action: () => onSubmitEstrutura({ id: item?.id }),
    },
  ];

  return (
    <Card title="Estrutura">
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined', loading }}
              options={options}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={estrutura}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                hide: disableActions,
                width: 100,
                getActions: ({ row, id }) => [
                  <GridActionsCellItem
                    icon={<Edit />}
                    label="Editar"
                    onClick={() =>
                      openDialog(<Modal estrutura={row} />, 'Editar Estrutura')
                    }
                  />,
                  <GridActionsCellItem
                    icon={<Delete />}
                    label="Excluir"
                    onClick={() => onDelete({ rotina, id })}
                  />,
                ],
              },
              {
                field: 'sequencia',
                headerName: 'Sequência',
                width: 100,
                sortable: false,
              },
              {
                field: 'componente',
                headerName: 'Componente',
                flex: 1,
                sortable: false,
                valueGetter: ({ row }) =>
                  row?.Produto?.ProdutoDescricaos?.find((f) =>
                    ['COMPLETA', 'REDUZIDA']?.includes(f?.tipo)
                  )?.descricao,
              },
              {
                field: 'quantidade',
                headerName: 'Quantidade',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  `${row?.quantidade || 0} ${
                    row?.Produto?.UnidadeMedida?.sigla || ''
                  }`,
              },
              {
                field: 'custo_unitario',
                headerName: 'Custo Unitário',
                type: 'number',
                width: 250,
                sortable: false,
                renderCell: (props) => <CustoCell {...props} />,
              },
              {
                field: 'custo_total',
                headerName: 'Custo Total',
                type: 'number',
                width: 250,
                sortable: false,
                renderCell: (props) => <CustoCell {...props} />,
              },
              {
                field: 'perda',
                headerName: 'Perda',
                type: 'number',
                width: 100,
                sortable: false,
                valueFormatter: ({ value }) => (value || 0) + '%',
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Estrutura;
