import React from 'react';
import Loader from 'components/Loader';
import Defeitos from './Defeitos';
import Locais from './Locais';

const Tabs = ({ tab, getLoading, ...props }) => {
  if (getLoading) {
    return <Loader />;
  }
  switch (tab) {
    case 0:
      return <Defeitos {...props} />;
    case 1:
      return <Locais {...props} />;
    default:
      return null;
  }
};

export default Tabs;
