import { BarChart } from '@mui/x-charts';
import Card from 'components/Card';

const Pendentes = ({ title = '', data = null }) => {
  const style = { flex: 1 };

  const formatHeader = () =>
    Boolean(data) ? Object?.keys(data)?.map((key) => key) : [''];

  const formatBody = () => formatHeader()?.map((k) => data[k] || 0);

  if (!Boolean(data)) {
    return null;
  }

  return (
    <Card style={style} title={title}>
      <BarChart
        xAxis={[{ data: formatHeader(), scaleType: 'band' }]}
        series={[{ data: formatBody(), color: '#22267B' }]}
        height={300}
      />
    </Card>
  );
};
export default Pendentes;
