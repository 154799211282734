import { useState } from 'react';
import { Description, Info, Link, Settings } from '@mui/icons-material';
import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { summarizer } from 'utils/functions';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import styles from './styles';
import moment from 'moment';

const getSize = (actions = []) => {
  const hasMenu = actions?.some((s) => s?.menu === true);
  const options = actions?.filter((f) => !f?.menu)?.length || 1;
  const size = hasMenu ? 50 * options + 50 : 50 * options;
  return size > 100 ? size : 100;
};

const openDoc = (item) => {
  const documento_id =
    item?.documento_id_origem ||
    item?.documento_id_vinculado ||
    item?.documento_id ||
    null;
  window.open(`/app/Documento/${documento_id}`);
};

const ItemCell = ({ row = {}, documento = {} }) => {
  const origem = row?.DocumentoItemOrigems[0] || null;
  const vinculo = row?.Vinculados[0] || null;
  const lines = [
    {
      label: 'Código do Produto',
      value: row?.produto_id,
    },
    {
      label: 'Referência',
      value: row?.Produto?.referencia,
    },
    {
      label: 'Referência do Fornecedor',
      value: row?.referencia,
      show: documento?.Especie?.grupo === 'ENTRADA',
    },
    {
      label: 'Saldo',
      value: `${row?.saldo?.toString()} ${row?.UnidadeMedida?.sigla || ''}`,
      show:
        documento?.situacao === 'PENDENTE' && documento?.conferido !== 'SIM',
    },
    {
      label: 'Localizador',
      value: row?.Produto?.localizador,
    },
  ];
  const getTooltip = (doc) => {
    let str = '';
    const especie = doc?.especie_origem || doc?.especie_vinculado;
    const natureza_operacao =
      doc?.natureza_operacao_origem || doc?.natureza_operacao_vinculado;
    const documento = doc?.documento_origem || doc?.documento_vinculado;
    const dtemissao = doc?.dtemissao;
    if (Boolean(especie)) {
      str += especie;
    }
    if (Boolean(natureza_operacao)) {
      str += ` DE ${natureza_operacao}`;
    }
    if (Boolean(documento)) {
      str += `\nNº do Documento: ${documento}`;
    }
    if (Boolean(dtemissao) && moment(dtemissao).isValid()) {
      str += `\nData de Emissão: ${moment(dtemissao).format(
        'DD/MM/YYYY [às] HH:mm'
      )}`;
    }
    return <Box sx={styles?.tooltip}>{str}</Box>;
  };
  return (
    <Box sx={styles?.cardContainer}>
      <Box sx={styles?.card}>
        <Typography
          sx={styles?.lineLabel({ flex: 1, title: true })}
          variant="body1"
        >
          {row?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value, show = true }, i) =>
            Boolean(value) &&
            show && (
              <Box key={i?.toString()} sx={styles?.line}>
                <Typography
                  sx={styles?.lineLabel({ flex: 2 })}
                  variant="caption"
                >
                  {label}:{' '}
                </Typography>
                <Typography
                  sx={styles?.lineValue({ flex: 5 })}
                  variant="caption"
                >
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
      {Boolean(row?.Cfo?.servico === 'SIM') && (
        <Chip
          label="Serviço"
          icon={<Settings />}
          size="small"
          color="primary"
          sx={styles?.chip}
        />
      )}
      {(Boolean(origem) || Boolean(vinculo)) && (
        <Box sx={styles?.rightIcon}>
          {Boolean(origem) && (
            <Tooltip title={getTooltip(origem)} placement="left-start">
              <IconButton
                color="primary"
                size="small"
                onClick={() => openDoc(origem)}
              >
                <Link fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {Boolean(vinculo) && (
            <Tooltip title={getTooltip(vinculo)} placement="left-start">
              <IconButton
                color="primary"
                size="small"
                onClick={() => openDoc(vinculo)}
              >
                <Link fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

const QuantidadeCell = ({ row = {} }) => {
  const { openModal } = useModal();
  const getQtd = (arr = []) => summarizer(arr, 'quantidade') || 0;
  const quantidade = row?.quantidade || 0;
  const um = row?.UnidadeMedida?.sigla || '';
  const bonificacoes = row?.Vinculos?.filter((f) =>
    [79]?.includes(f?.natureza_operacao_id)
  );
  const consignacoes = row?.Vinculos?.filter((f) =>
    [52]?.includes(f?.natureza_operacao_id)
  );
  const retornos = row?.Vinculos?.filter((f) =>
    [21, 24, 45, 57, 63]?.includes(f?.natureza_operacao_id)
  );
  const garantias = row?.Vinculos?.filter((f) =>
    [54]?.includes(f?.natureza_operacao_id)
  );
  const devolucoes = row?.Vinculos?.filter((f) =>
    [3, 4, 7, 8]?.includes(f?.natureza_operacao_id)
  );
  const compras = row?.Destinos?.filter(
    (f) => f?.especie_grupo === 'ENTRADA' && f?.natureza_indvndcmp === 'SIM'
  );
  const vendas = row?.Destinos?.filter(
    (f) => f?.especie_grupo === 'SAIDA' && f?.natureza_indvndcmp === 'SIM'
  );
  const requisicoes = row?.Destinos?.filter(
    (f) => f?.especie_id === 2 && f?.natureza_operacao_id === 1
  );
  const lines = [
    { label: 'Bonificações', value: getQtd(bonificacoes) },
    { label: 'Compras', value: getQtd(compras) },
    { label: 'Consignações', value: getQtd(consignacoes) },
    { label: 'Devoluções', value: getQtd(devolucoes) },
    { label: 'Garantias', value: getQtd(garantias) },
    { label: 'Requisições', value: getQtd(requisicoes) },
    { label: 'Retornos', value: getQtd(retornos) },
    { label: 'Vendas', value: getQtd(vendas) },
  ];
  const hasValues = lines?.some((s) => Boolean(s?.value));

  return (
    <Box sx={styles?.cardContainer}>
      <Box sx={styles?.card}>
        <Typography
          textAlign="end"
          sx={styles?.lineLabel({ flex: 1, wrap: true, title: hasValues })}
          variant="body1"
        >
          {quantidade} {um}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} sx={styles?.line}>
                <Typography
                  sx={styles?.lineLabel({ flex: 1, wrap: true })}
                  variant="body2"
                >
                  {label}:{' '}
                </Typography>
                <Typography
                  sx={styles?.lineValue({ wrap: true })}
                  variant="body2"
                >
                  {value} {um}
                </Typography>
              </Box>
            )
        )}
      </Box>
      {hasValues && (
        <IconButton
          color="primary"
          size="small"
          sx={styles?.rightIcon}
          onClick={() => openModal(<GeradosModal item={row} />)}
        >
          <Info fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

const ValoresCell = ({ row = {}, documento = {} }) => {
  const lines = [
    { label: 'Desconto', value: row?.desconto_vlr || 0 },
    {
      label: 'Impostos',
      value: row?.imposto || 0,
      show: documento?.Especie?.fiscal === 'SIM',
    },
    { label: 'Frete', value: row?.frete || 0 },
    { label: 'Seguro', value: row?.seguro || 0 },
    { label: 'Acessórias', value: row?.despac || 0 },
    { label: 'Eventuais', value: row?.eventuais || 0 },
    { label: 'Outros', value: row?.outros || 0 },
  ];
  return (
    <Box sx={styles?.card}>
      {lines?.map(({ label, value, show = false }, i) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const link = i === 1 && row?.impostos?.length;
        if (Boolean(value) || show) {
          return (
            <Box key={i?.toString()} sx={styles?.line}>
              <Typography
                variant="body2"
                sx={styles?.lineLabel({ flex: 1, link })}
                onClick={(e) => link && setAnchorEl(e?.currentTarget)}
              >
                {label}:{' '}
              </Typography>
              <Typography
                variant="body2"
                sx={styles?.lineValue({ link, wrap: true })}
                onClick={(e) => link && setAnchorEl(e?.currentTarget)}
              >
                {value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
              <PopUp
                anchorEl={anchorEl}
                impostos={row?.impostos}
                setAnchorEl={setAnchorEl}
              />
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

const PopUp = ({ anchorEl, setAnchorEl, impostos = [] }) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={() => setAnchorEl(null)}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
  >
    {impostos?.map(({ color, descricao, valor }, i) => (
      <Box key={i?.toString()} sx={styles?.popover}>
        <Typography variant="subtitle2" color={color}>
          {descricao}:
        </Typography>
        <Typography variant="subtitle2" color={color}>
          {valor?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Box>
    ))}
  </Popover>
);

const GeradosModal = ({ item }) => {
  const { closeModal } = useModal();
  const gerados = [...item?.Vinculos, ...item?.Destinos];
  const getPrimary = (value) => {
    let str = '';
    if (Boolean(value?.especie)) {
      str += value?.especie;
    }
    if (Boolean(value?.natureza)) {
      str += ` DE ${value?.natureza}`;
    }
    if (Boolean(value?.documento)) {
      str += ` - Nº ${value?.documento}`;
    }
    return str;
  };
  const getSecondary = (value) => {
    let str = '';
    if (Boolean(value?.dtemissao) && moment(value?.dtemissao)?.isValid()) {
      str += `Emitida em ${moment(value?.dtemissao).format(
        'DD/MM/YYYY [às] HH:mm'
      )}`;
    }
    return str;
  };
  return (
    <>
      <DialogTitle>Documentos Gerados</DialogTitle>
      <DialogContent>
        <List>
          {gerados?.map((g, i) => (
            <Box key={i?.toString()}>
              {i === 0 && <Divider />}
              <ListItem>
                <ListItemIcon>
                  <IconButton color="primary" onClick={() => openDoc(g)}>
                    <Description />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  primary={getPrimary(g)}
                  secondary={getSecondary(g)}
                />
              </ListItem>
              <Divider />
            </Box>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary" variant="contained">
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

const columns = ({ documento, actions = [] }) => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      sortable: false,
    },
    {
      field: 'sequencia',
      headerName: 'Sequência',
      type: 'number',
      width: 100,
      sortable: false,
    },
    {
      field: 'item',
      headerName: 'Item',
      width: 500,
      sortable: false,
      renderCell: (props) => <ItemCell {...props} documento={documento} />,
    },
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      type: 'number',
      width: 300,
      sortable: false,
      renderCell: (props) => <QuantidadeCell {...props} />,
    },
    {
      field: 'preco',
      headerName: 'Preço',
      type: 'number',
      width: 200,
      sortable: false,
      valueFormatter: ({ value }) =>
        (value || 0)?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      field: 'valores',
      headerName: 'Outros Valores',
      type: 'number',
      width: 200,
      sortable: false,
      renderCell: (props) => <ValoresCell {...props} documento={documento} />,
    },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      type: 'number',
      width: 200,
      sortable: false,
      valueFormatter: ({ value }) =>
        (value || 0)?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
  ];

  if (Boolean(actions?.length)) {
    columns?.unshift({
      field: 'actions',
      headerName: 'Ações',
      type: 'actions',
      width: getSize(actions),
      getActions: (params) =>
        actions
          ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          ?.map(
            ({ icon, name, action, color, menu, show = () => true }) =>
              show(params) && (
                <GridActionsCellItem
                  icon={
                    menu ? (
                      <Icon sx={{ color }}>{icon}</Icon>
                    ) : (
                      <Tooltip title={name}>
                        <Icon sx={{ color }}>{icon}</Icon>
                      </Tooltip>
                    )
                  }
                  label={name}
                  showInMenu={menu}
                  onClick={() => action(params)}
                  disabled={!action}
                  sx={styles?.actionCell}
                />
              )
          )
          ?.filter(Boolean),
    });
  }

  return columns;
};

export default columns;
