const styles = {
  image: {
    objectFit: 'contain',
    maxHeight: '75px',
    overflow: 'hidden',
    flex: 1,
    mr: 1,
    alignSelf: 'center',
  },
};

export default styles;
