import React from 'react';
import { Grid } from '@mui/material';
import { dropTipoManut, dropStatusDocumento } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('status') && (
        <Grid item xs={12}>
          <Dropdown
            name="status"
            control={control}
            label="Status"
            options={dropStatusDocumento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('status') && (
        <Grid item xs={12}>
          <Dropdown
            name="situacao"
            control={control}
            label="Situação"
            options={drops?.StatusDocumento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <Input
            name="documento"
            control={control}
            label="Nº do Documento"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <InputMask name="vldocto" control={control} label="Valor" />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Data de Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={6}>
          <InputMask
            name="przent.i"
            control={control}
            label="Prazo de Entrega Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={6}>
          <InputMask
            name="przent.f"
            control={control}
            label="Prazo de Entrega Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={6}>
          <InputMask
            name="dtmodificacao.i"
            control={control}
            label="Última Modificação Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={6}>
          <InputMask
            name="dtmodificacao.f"
            control={control}
            label="Última Modificação Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('cliente') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="cliente"
            control={control}
            label="Cliente"
            options={drops?.Entidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('cliente') && (
        <Grid item xs={12}>
          <Input name="contato" control={control} label="Contato" />
        </Grid>
      )}
      {checkDisplay('equipamento') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="equipamento"
            control={control}
            label="Equipamento"
            options={drops?.Equipamento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('equipamento') && (
        <Grid item xs={12}>
          <Input name="localizacao" control={control} label="Localização" />
        </Grid>
      )}
      {checkDisplay('manutencao') && (
        <Grid item xs={12}>
          <Dropdown
            name="manutencao"
            control={control}
            label="Tipo da Manutenção"
            options={dropTipoManut}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('manutencao') && (
        <Grid item xs={12}>
          <Dropdown
            name="atendente"
            control={control}
            label="Atendente"
            options={drops?.Users}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('manutencao') && (
        <Grid item xs={12}>
          <Dropdown
            name="tecnico"
            control={control}
            label="Técnico"
            options={drops?.Users}
            multiple
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
