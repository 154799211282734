const styles = {
  cardContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    flex: 1,
  },
  line: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineLabel: ({ flex = 0, link = false, wrap = false, title = false }) => ({
    flex,
    fontWeight: 'bold',
    cursor: link ? 'pointer' : 'auto',
    whiteSpace: wrap ? 'nowrap' : 'normal',
    textDecoration: link ? 'underline' : 'none',
    mb: title ? 1 : 0,
  }),
  lineValue: ({ flex = 0, link = false, wrap = false, title = false }) => ({
    flex,
    cursor: link ? 'pointer' : 'auto',
    whiteSpace: wrap ? 'nowrap' : 'normal',
    textDecoration: link ? 'underline' : 'none',
    mb: title ? 1 : 0,
  }),
  popover: {
    p: [1],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
  },
  chip: {
    ml: '10px',
  },
  rightIcon: {
    ml: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  tooltip: {
    whiteSpace: 'pre-line',
  },
  actionCell: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    logo: {
      width: '100%',
      maxHeight: 50,
      maxWidth: 200,
    },
  },
};

export default styles;
