import React from 'react';
import Loader from 'components/Loader';
import Item from './Item';
import Estrutura from './Estrutura';
import Processos from './Processos';
import Tributos from './Tributos';
import Margem from './Margem';
import Vinculos from './Vinculos';
import Variações from './Variações';
import Movimentações from './Movimentações';

const Tabs = ({ tab, getLoading, ...props }) => {
  if (getLoading) {
    return <Loader />;
  }
  switch (tab) {
    case 0:
      return <Item {...props} />;
    case 1:
      return <Estrutura {...props} />;
    case 2:
      return <Processos {...props} />;
    case 3:
      return <Tributos {...props} />;
    case 4:
      return <Margem {...props} />;
    case 5:
      return <Vinculos {...props} />;
    case 6:
      return <Variações {...props} />;
    case 7:
      return <Movimentações {...props} />;
    default:
      return null;
  }
};

export default Tabs;
