const styles = {
  container: {
    flex: 1,
  },
  chip: {
    m: 0.5,
    maxWidth: 372,
  },
};

export default styles;
