import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Card from 'components/Card';
import moment from 'moment';
import styles from './styles';

const Vinculos = ({ item = {}, gridProps = {} }) => {
  const origem = item?.DocumentoItemOrigems[0] || null;
  const vinculo = item?.Vinculados[0] || null;
  const gerados = [...item?.Vinculos, ...item?.Destinos]?.map((m, i) => ({
    ...m,
    index: i + 1,
  }));

  const openDoc = (item) => {
    const documento_id =
      item?.documento_id_origem ||
      item?.documento_id_vinculado ||
      item?.documento_id ||
      null;
    window.open(`/app/Documento/${documento_id}`);
  };

  const getText = () => {
    let str = '';
    const doc = origem || vinculo;
    const especie = doc?.especie_origem || doc?.especie_vinculado;
    const natureza_operacao =
      doc?.natureza_operacao_origem || doc?.natureza_operacao_vinculado;
    const documento = doc?.documento_origem || doc?.documento_vinculado;
    const dtemissao = doc?.dtemissao;
    if (Boolean(especie)) {
      str += especie;
    }
    if (Boolean(natureza_operacao)) {
      str += ` DE ${natureza_operacao}`;
    }
    if (Boolean(documento)) {
      str += `, nº ${documento}`;
    }
    if (Boolean(dtemissao) && moment(dtemissao).isValid()) {
      str += ` emitido em ${moment(dtemissao).format('DD/MM/YYYY [às] HH:mm')}`;
    }
    return str?.toLowerCase();
  };

  const DocumentoCell = ({ row }) => {
    const lines = [
      {
        label: 'Nº do Documento',
        value: row?.documento,
      },
      {
        label: 'Data de Emissão',
        value:
          moment(row?.dtemissao).isValid() &&
          moment(row?.dtemissao).format('DD/MM/YYYY [às] HH:mm'),
      },
    ];

    return (
      <Box flex={1}>
        <Typography fontWeight="bold" variant="body1" mb={1}>
          {row?.especie || ''}
          {Boolean(row?.natureza) && ` DE ${row?.natureza}`}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  return (
    <Grid container spacing={2}>
      {Boolean(origem || vinculo) && (
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="caption"
            color="error"
            sx={styles?.link}
            onClick={() => openDoc(origem || vinculo)}
          >
            Este item foi gerado por um(a) {getText()}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card title="Vínculos">
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="caption">
                Documentos gerados a partir deste item
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={gerados}
                columns={[
                  {
                    field: 'documento',
                    headerName: 'Documento',
                    flex: 1,
                    sortable: false,
                    renderCell: (props) => <DocumentoCell {...props} />,
                  },
                  {
                    field: 'quantidade',
                    headerName: 'Quantidade',
                    type: 'number',
                    width: 150,
                    sortable: false,
                    valueGetter: ({ row }) =>
                      `${row?.quantidade || 0} ${
                        item?.UnidadeMedida?.sigla || ''
                      }`,
                  },
                ]}
                getRowId={(row) => row?.index}
                {...gridProps}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Vinculos;
