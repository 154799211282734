import React, { useContext, useEffect } from 'react';
import {
  Grid,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Send } from '@mui/icons-material';
import { decryptURL } from 'utils/functions';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';

const Direcionamento = () => {
  const titulo = 'Direcionamento Técnico';
  const navigate = useNavigate();
  const { hash } = useParams();
  const { drops } = useContext(DropsContext);
  const { documento, getLoading, getDocumento, postDocumento, postLoading } =
    useContext(DocumentosContext);
  const decrypt = decryptURL(hash);

  useEffect(() => {
    if (Boolean(decrypt)) {
      getDocumento(decrypt?.id);
    } else {
      navigate(-1);
    }
  }, []);

  const onSubmit = (tecnico_id) => {
    const origens = [];
    documento?.DocumentoItems?.map((di) =>
      di?.DocumentoItemOrigems?.map((dio) => origens?.push(dio))
    );
    let data = {
      Servico: {
        ...documento?.DocumentoServico,
        documento_id: documento?.id,
        tecnico_id,
      },
      Registros: [{ documento_id: documento?.id, status_id: 45 }],
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  const RenderCards = ({ tecnicos = [] }) =>
    tecnicos?.map((t, i) => (
      <Grid item xs={12} sm={6} key={i?.toString()}>
        <Card>
          <CardHeader
            avatar={<Avatar sx={{ bgcolor: 'primary.main' }} />}
            title={t?.name}
            action={
              <IconButton
                onClick={() => onSubmit(t?.id)}
                disabled={postLoading}
              >
                {postLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Send color="primary" />
                )}
              </IconButton>
            }
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              • {t?.envio} {t?.envio > 1 ? 'Orçamentos' : 'Orçamento'} à enviar
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • {t?.aprovacao} {t?.aprovacao > 1 ? 'Orçamentos' : 'Orçamento'}{' '}
              aguardando aprovação
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • {t?.pecas} {t?.pecas > 1 ? 'Orçamentos' : 'Orçamento'}{' '}
              aguardando peças
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • {t?.andamento} {t?.andamento > 1 ? 'Serviços' : 'Serviço'} em
              Execução
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <RenderCards tecnicos={drops?.Tecnico} />
        </Grid>
      </Card>
    </Container>
  );
};

export default Direcionamento;
