import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Visibility } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Grid, Typography } from '@mui/material';
import { DropsContext } from 'contexts/DropsContext';
import { findOnArray, summarizer } from 'utils/functions';
import { encryptURL } from 'utils/functions';
import { useDialog } from 'components/Modals';
import localeText from 'utils/localeText';
import Card from 'components/Card';
import Dropdown from 'components/Dropdown';

const ImpostosModal = ({ documento = {} }) => {
  const navigate = useNavigate();
  const defaultValues = { imposto: 1, itens: [] };
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { control, watch, setValue } = useForm({ defaultValues });
  const entidade = findOnArray(documento?.cadastro_id, drops?.Entidade);
  const contribuinte = findOnArray(
    documento?.contribuinte_id,
    drops?.Contribuinte
  );
  const gridStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  };
  const headerLines = [
    { label: 'Nome', value: entidade?.label },
    {
      label: 'Contribuinte',
      value:
        contribuinte?.Código +
        (contribuinte?.Código ? ` - ${contribuinte?.label}` : ''),
    },
    { label: 'Fantasia', value: entidade?.Fantasia },
    { label: 'Uso Final', value: documento?.usofinal },
    { label: 'Cidade', value: entidade?.Cidade },
  ];

  useEffect(() => {
    const itens = documento?.DocumentoItems?.map((di) => {
      let ret = {
        id: di?.id,
        descricao: di?.descricao,
        referencia: di?.referencia,
        cfo: (di?.Cfo?.cfo || '') + ' - ' + (di?.Cfo?.descricao || ''),
        ncm: (di?.Cfiscal?.ncm || '') + ' - ' + (di?.Cfiscal?.descricao || ''),
        orgtrib: di?.Produto?.OrigemTributarium?.origem,
      };
      di?.DocumentoItemImpostos?.map((dii) => {
        ret[dii?.tributo_id?.toString()] = {
          aliquota: dii?.percentual || 0,
          basecalculo: dii?.basecalculo || 0,
          valor: dii?.valor || 0,
          cst: dii?.SituacaoTributarium?.cst || '',
          situacao: dii?.SituacaoTributarium?.descricao || '',
        };
      });
      return ret;
    });
    setValue('itens', itens);
  }, []);

  const ItemCell = ({ row = {} }) => {
    const lines = [
      { label: 'Referência', value: row?.referencia },
      { label: 'Origem Tributária', value: row?.orgtrib },
      { label: 'CFOP', value: row?.cfo },
      { label: 'NCM', value: row?.ncm },
    ];

    return (
      <Box flex={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {row?.descricao}
        </Typography>
        {lines?.map(({ label, value }, i) => (
          <Box key={i?.toString()} flex={1} display="flex">
            <Typography flex={1} fontWeight="bold" variant="caption">
              {label}:{' '}
            </Typography>
            <Typography flex={3} variant="caption">
              {value || '-'}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const TributoCell = ({ row = {} }) => {
    const tributo = row[watch('imposto')] || null;
    const lines = [
      {
        label: 'Alíquota',
        value: tributo && tributo?.aliquota + '%',
      },
      {
        label: 'Base de Cálculo',
        value:
          tributo &&
          tributo?.basecalculo?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        label: 'Valor',
        value:
          tributo &&
          tributo?.valor?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
      },
      {
        label: 'Situação Tributária',
        value: tributo && tributo?.cst + ' - ' + tributo?.situacao,
      },
    ];
    return (
      <Box flex={1}>
        {lines?.map(({ label, value }, i) => (
          <Box key={i?.toString()} flex={1} display="flex">
            <Typography flex={1} fontWeight="bold" variant="body2">
              {label}:{' '}
            </Typography>
            <Typography flex={3} variant="body2">
              {value || '-'}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Card>
          <Grid container>
            {headerLines?.map(({ label, value }) => (
              <Grid item xs={12} sm={6}>
                <Box display="flex">
                  <Typography flex={1} fontWeight="bold">
                    {label}:
                  </Typography>
                  <Typography flex={2}>{value || '-'}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} {...gridStyle}>
        <Dropdown
          name="imposto"
          control={control}
          label="Tributo"
          options={drops?.Tributo}
        />
        <Typography variant="h6" align="center" color="primary">
          Total do Tributo:{' '}
          {summarizer(
            watch('itens')
              ?.map((m) => m[watch('imposto')])
              ?.filter(Boolean),
            'valor'
          )?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={watch('itens')}
          columns={[
            {
              field: 'actions',
              type: 'actions',
              width: 100,
              headerName: 'Ações',
              getActions: ({ row }) => [
                <GridActionsCellItem
                  icon={<Visibility />}
                  label="Detalhes"
                  onClick={() => {
                    navigate(`/app/Documento/Item/${encryptURL(row)}`);
                    closeDialog();
                  }}
                />,
              ],
            },
            {
              field: 'item',
              headerName: 'Item',
              flex: 1,
              sortable: false,
              renderCell: ({ row }) => <ItemCell row={row} />,
            },
            {
              field: 'tributo',
              headerName: findOnArray(
                watch('imposto'),
                drops?.Tributo,
                'label'
              ),
              flex: 1,
              sortable: false,
              renderCell: ({ row }) => <TributoCell row={row} />,
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          disableSelectionOnClick
          getRowHeight={() => 'auto'}
          localeText={localeText}
        />
      </Grid>
    </Grid>
  );
};
export default ImpostosModal;
