import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { VendaProvider } from 'contexts/VendaContext';
import { AgendaProvider } from 'contexts/AgendaContext';
import { MarcasProvider } from 'contexts/MarcasContext';
import { ModelosProvider } from 'contexts/ModelosContext';
import { EntidadesProvider } from 'contexts/EntidadesContext';
import { EquipamentosProvider } from 'contexts/EquipamentosContext';
import { DocumentosProvider } from 'contexts/DocumentosContext';
import { ServicosProvider } from 'contexts/ServicosContext';
import { OrçamentosProvider } from 'contexts/OrçamentosContext';
import { DashboardProvider } from 'contexts/DashboardContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <VendaProvider>
        <AgendaProvider>
          <EntidadesProvider>
            <ModelosProvider>
              <MarcasProvider>
                <EquipamentosProvider>
                  <DocumentosProvider>
                    <ServicosProvider>
                      <OrçamentosProvider>
                        <DashboardProvider>
                          <RelatoriosProvider>
                            <DropsProvider>
                              <GridProvider>
                                <ModalProvider>
                                  <DialogProvider>
                                    <DrawerProvider>{children}</DrawerProvider>
                                  </DialogProvider>
                                </ModalProvider>
                              </GridProvider>
                            </DropsProvider>
                          </RelatoriosProvider>
                        </DashboardProvider>
                      </OrçamentosProvider>
                    </ServicosProvider>
                  </DocumentosProvider>
                </EquipamentosProvider>
              </MarcasProvider>
            </ModelosProvider>
          </EntidadesProvider>
        </AgendaProvider>
      </VendaProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
