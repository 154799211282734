import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Card from 'components/Card';
import moment from 'moment';

const Movimentações = ({ item = {}, gridProps = {} }) => {
  const EstoqueCell = ({ row }) => {
    const lines = [
      {
        label: 'Local de Estoque',
        value: row?.celocal,
      },
      {
        label: 'Endereço de Estoque',
        value: row?.ceendereco,
      },
      {
        label: 'Rastreabilidade',
        value: row?.rastreabilidade,
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const renderMov = ({ row }) => {
    let str = '';
    if (Boolean(row?.user)) {
      str += `Feito por ${row?.user}`;
    }
    if (Boolean(row?.dtmovimento) && moment(row?.dtmovimento).isValid()) {
      str += ` em ${moment(row?.dtmovimento).format('DD/MM/YYYY [às] HH:mm')}`;
    }
    return str;
  };

  return (
    <Card title="Movimentações de Estoque">
      <DataGrid
        rows={item?.Cemovimentos}
        columns={[
          {
            field: 'produto',
            headerName: 'Produto',
            width: 400,
            sortable: false,
            valueGetter: ({ row }) => row?.produto || item?.descricao || '',
          },
          {
            field: 'quantidade',
            headerName: 'Quantidade',
            type: 'number',
            width: 150,
            sortable: false,
            valueGetter: ({ row }) =>
              `${row?.quantidade || 0} ${item?.UnidadeMedida?.sigla || ''}`,
          },
          {
            field: 'variacao',
            headerName: 'Variação',
            width: 200,
            sortable: false,
          },
          {
            field: 'estoque',
            headerName: 'Estoque',
            width: 500,
            sortable: false,
            renderCell: (props) => <EstoqueCell {...props} />,
          },
          {
            field: 'movimentacao',
            headerName: 'Movimentação',
            width: 500,
            sortable: false,
            valueGetter: renderMov,
          },
        ]}
        {...gridProps}
      />
    </Card>
  );
};

export default Movimentações;
