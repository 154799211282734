import { useState } from 'react';
import {
  Box,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  Pagination,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { Search, TrendingDown, TrendingUp } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useModal } from '..';
import Blank from 'assets/blank.png';
import localeText from 'utils/localeText';
import Button from 'components/Button';
import Input from 'components/Input';
import api from 'services/api';
import moment from 'moment';
import styles from './styles';

const SaldoModal = ({ onSubmit, vendedor_id, single = false }) => {
  const defaultValues = { produto: '', vendedor_id, saldo: false };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [itens, setItens] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(0);

  const loadData = async (values, { page = 0, reset = false }) => {
    try {
      const body = { ...values, page };
      setLoading(true);
      if (reset) {
        setSelected([]);
      }
      const { data } = await api.post(`/Comercial/Venda/ProdutoSaldo`, body);
      setPage(page);
      setSize(data?.count || 0);
      setItens(data?.rows || []);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const ProdutoCell = ({ row = {} }) => {
    const lines = [
      {
        label: 'Referência',
        value: row?.referencia,
      },
      {
        label: 'Marca',
        value: row?.marca,
      },
      {
        label: 'Localizador',
        value: row?.localizador,
      },
      {
        label: 'Especificação',
        value: row?.especificacao,
      },
    ];

    return (
      <Box display="flex" flex={1}>
        <Box component="img" src={row?.url || Blank} sx={styles?.image} />
        <Box flex={4}>
          <Typography fontWeight="bold" variant="body1" mb={1}>
            {row?.descricao}
          </Typography>
          {lines?.map(
            ({ label, value }, i) =>
              Boolean(value) && (
                <Box key={i?.toString()} display="flex">
                  <Typography flex={1} variant="caption" fontWeight="bold">
                    {label}:{' '}
                  </Typography>
                  <Typography flex={4} variant="caption">
                    {value}
                  </Typography>
                </Box>
              )
          )}
        </Box>
      </Box>
    );
  };

  const SaldoCell = ({ row = {} }) => {
    const color = row?.saldo > 0 ? 'success' : 'error';
    const label = row?.saldo + ' ' + (row?.unidade_medida || '');
    const icon = row?.saldo > 0 ? <TrendingUp /> : <TrendingDown />;

    return <Chip icon={icon} label={label} color={color} variant="outlined" />;
  };

  const PrecoCell = ({ row = {} }) => {
    const preco = (row?.preco || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    const att =
      Boolean(row?.atualizacao_preco) &&
      moment(row?.atualizacao_preco).isValid()
        ? moment(row?.atualizacao_preco).format('DD/MM/YYYY [às] HH:mm')
        : null;

    return (
      <Box flex={1} textAlign="end">
        <Typography variant="body2" fontWeight="bold">
          {preco}
        </Typography>
        {Boolean(att) && (
          <Typography variant="caption">Atualizado em {att}</Typography>
        )}
      </Box>
    );
  };

  const CustoCell = ({ row = {} }) => {
    const custo = (row?.custo || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    const att =
      Boolean(row?.atualizacao_custo) &&
      moment(row?.atualizacao_custo).isValid()
        ? moment(row?.atualizacao_custo).format('DD/MM/YYYY [às] HH:mm')
        : null;

    return (
      <Box flex={1} textAlign="end">
        <Typography variant="body2" fontWeight="bold">
          {custo}
        </Typography>
        {Boolean(att) && (
          <Typography variant="caption">Atualizado em {att}</Typography>
        )}
      </Box>
    );
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        page={page + 1}
        onChange={(_, page) => handleSubmit(loadData)({ page: page - 1 })}
      />
    );
  };

  const onConfirm = () => {
    const data = single ? selected[0] || null : selected;
    onSubmit(data);
  };

  return (
    <>
      <DialogTitle>Consultar Saldo de Produtos</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Input
              name="produto"
              placeholder="Buscar..."
              control={control}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e) =>
                e?.key === 'Enter' && handleSubmit(loadData)({ reset: true })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} textAlign="end">
            <Controller
              control={control}
              name="saldo"
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Somente com saldo"
                  control={<Checkbox checked={value} onChange={onChange} />}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleSubmit(loadData)({ reset: true })}
              loading={loading}
            >
              Buscar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={itens}
              columns={[
                {
                  field: 'produto',
                  headerName: 'Produto',
                  flex: 1,
                  sortable: false,
                  renderCell: (props) => <ProdutoCell {...props} />,
                },
                {
                  field: 'saldo',
                  headerName: 'Saldo',
                  type: 'number',
                  width: 150,
                  sortable: false,
                  renderCell: (props) => <SaldoCell {...props} />,
                },
                {
                  field: 'preco',
                  headerName: 'Preço',
                  type: 'number',
                  width: 250,
                  sortable: false,
                  renderCell: (props) => <PrecoCell {...props} />,
                },
                {
                  field: 'custo',
                  headerName: 'Custo',
                  type: 'number',
                  width: 250,
                  sortable: false,
                  renderCell: (props) => <CustoCell {...props} />,
                },
              ]}
              autoHeight
              pagination
              pageSize={10}
              rowCount={size}
              density="compact"
              loading={loading}
              disableColumnMenu
              checkboxSelection
              showCellRightBorder
              showColumnRightBorder
              disableSelectionOnClick
              paginationMode="server"
              localeText={localeText}
              keepNonExistentRowsSelected
              getRowHeight={() => 'auto'}
              selectionModel={selected?.map((s) => s?.id)}
              onSelectionModelChange={(ids) => {
                let arr = ids?.map((id) => itens?.find((f) => f?.id === id));
                if (single) {
                  if (Boolean(arr?.length > 1)) {
                    arr = arr?.slice(1, 2);
                  } else {
                    arr = arr?.slice(0, 1);
                  }
                }
                setSelected(arr);
              }}
              components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          loading={loading}
        >
          Confirmar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default SaldoModal;
