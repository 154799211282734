import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import Card from 'components/Card';
import Button from 'components/Button';
import InputMask from 'components/InputMask';

const Item = ({
  onSubmit,
  item = {},
  documento = {},
  loading = false,
  disableActions = false,
}) => {
  const margem = item?.DocumentoItemMc || null;
  const defaultValues = {
    documento_item_id: margem?.id,
    custo: margem?.custo || '',
    internacional: margem?.internacional || '',
    fretend: margem?.fretend || '',
    segurond: margem?.segurond || '',
    impfednd: margem?.impfednd || '',
    comissaond: margem?.comissaond || '',
    medio: margem?.medio || '',
    medioc: margem?.medioc || '',
    custocompra: margem?.custocompra || '',
    custocomprac: margem?.custocomprac || '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const isNotEntrada = documento?.NaturezaOperacao?.operacao !== 'ENTRADA';

  const onConfirm = (Margem) => {
    const data = {
      Itens: [{ id: item?.id, documento_id: item?.documento_id, Margem }],
    };
    onSubmit(data);
  };

  return (
    <Card title="Margem de Contribuição">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputMask name="custo" control={control} label="Custo" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="internacional"
            control={control}
            label="Internacionalização"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="fretend" control={control} label="Frete (ND)" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="segurond" control={control} label="Seguro (ND)" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="impfed"
            control={control}
            label="Impostos Federais (ND)"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="comissaond"
            control={control}
            label="Comissão (ND)"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="medio"
            control={control}
            label="Custo Médio"
            disabled={isNotEntrada}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="medioc"
            control={control}
            label="Custo Médio Contábil"
            disabled={isNotEntrada}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="custocompra"
            control={control}
            label="Custo de Compra"
            disabled={isNotEntrada}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="custocomprac"
            control={control}
            label="Custo de Compra Contábil"
            disabled={isNotEntrada}
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              loading={loading}
              onClick={handleSubmit(onConfirm)}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Item;
