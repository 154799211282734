import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { encryptURL } from 'utils/functions';
import { useDialog } from 'components/Modals';
import ItemModal from 'components/Modals/ItemModal';
import ButtonMenu from 'components/ButtonMenu';
import ItensGrid from 'components/ItensGrid';
import Card from 'components/Card';
import AverageModal from './Modals/AverageModal';
import ImpostosModal from './Modals/ImpostosModal';
import styles from './styles';

const Itens = ({
  user,
  onSubmit,
  onDelete,
  onSubmitCfo,
  setSelected,
  selected = [],
  documento = {},
  onSubmitImposto,
  loading = false,
  disableActions = false,
}) => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const rotina = 'DocumentoItem';
  const itens = documento?.DocumentoItems || [];
  const totais = documento?.Totais || null;

  const onSubmitItem = ({ adicionais, ...data }) => onSubmit({ Itens: [data] });

  const onAdd = () =>
    openDialog(
      <ItemModal documento={documento} onSubmit={onSubmitItem} />,
      'Adicionar Item'
    );

  const onEdit = (item) =>
    openDialog(
      <ItemModal item={item} documento={documento} onSubmit={onSubmitItem} />,
      'Editar Item'
    );

  const onRemoveEstrutura = () => {
    if (Boolean(selected?.length)) {
      const Itens = [];
      selected
        ?.filter((f) => Boolean(f?.DocumentoItemEstruturas?.length))
        ?.map((d) =>
          Itens?.push({
            id: d?.id,
            documento_id: d?.documento_id,
            Estruturas: d?.DocumentoItemEstruturas?.map((e) => ({
              ...e,
              retornavel: 'NAO',
            })),
          })
        );
      if (Boolean(Itens?.length)) {
        onSubmit({ Itens });
      } else {
        return toastWarning('Itens selecionados não possuem estrutura');
      }
    } else {
      return toastWarning('Selecione ao menos um item');
    }
  };

  const onOutrosValores = () => {
    if (Boolean(itens?.length)) {
      openDialog(
        <AverageModal onSubmit={onSubmit} documento={documento} />,
        'Outros Valores'
      );
    } else {
      toastWarning('Documento não possui item');
    }
  };

  const options = [
    {
      name: 'Adicionar Item',
      icon: 'add',
      action: onAdd,
    },
    // {
    //   name: 'Recarregar CFOP',
    //   icon: 'refresh',
    //   action: onSubmitCfo,
    // },
    // {
    //   name: 'Recarregar Tributos',
    //   icon: 'refresh',
    //   action: onSubmitImposto,
    // },
    // {
    //   name: 'Remover Estrutura Retornável',
    //   icon: 'extension_off',
    //   action: onRemoveEstrutura,
    // },
    {
      name: 'Outros Valores',
      icon: 'add',
      show: Boolean(itens?.length),
      action: onOutrosValores,
    },
    // {
    //   name: 'Formação de Preço',
    //   icon: 'price_change',
    //   show:
    //     Boolean(documento?.Especie?.natureza === 'FATURA') &&
    //     Boolean(documento?.Especie?.grupo === 'ENTRADA'),
    //   action: () => navigate(`/app/Documento/FormacaoDePreco/${documento?.id}`),
    // },
    // {
    //   name: 'Vincular Itens',
    //   icon: 'link',
    //   action: () => navigate(`/app/Documento/Vinculo/${documento?.id}`),
    // },
    {
      name: 'Gerar Garantia',
      icon: 'post_add',
      show: !Boolean(user?.permissoes?.block_gera_garantia),
      action: () => {
        const itens = (
          Boolean(selected?.length) ? selected : documento?.DocumentoItems
        )?.map((m) => m?.id);
        const hash = encryptURL({ id: documento?.id, itens });
        navigate(`/app/Documento/Garantia/${hash}`);
      },
    },
    {
      name: 'Gerar Requisição de Compra',
      icon: 'post_add',
      show:
        Boolean(documento) &&
        [8, 9, 16]?.includes(documento?.especie_id) &&
        !Boolean(user?.permissoes?.block_gera_req_compra) &&
        documento?.situacao === 'PENDENTE',
      action: () => {
        const params = Boolean(selected?.length)
          ? `${documento?.id}/${JSON.stringify(selected?.map((m) => m?.id))}`
          : `${documento?.id}`;
        window.open(
          `https://suprimentos.eprom2.com.br/app/Requisicoes/Gerar/${params}`
        );
      },
    },
  ];

  const actions = [
    {
      name: 'Editar',
      icon: 'edit',
      action: ({ row }) => onEdit(row),
      show: () => !disableActions,
    },
    {
      name: 'Excluir',
      icon: 'delete',
      action: ({ id }) => onDelete({ rotina, id }),
      show: () => !disableActions,
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/Item/${encryptURL(row)}`),
    },
  ];

  const lines = [
    {
      label: 'Peso',
      value: totais?.peso || 0,
    },
    {
      label: 'Volume',
      value: totais?.volume || 0,
    },
    {
      label: 'Preço',
      value: (totais?.preco || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Seguro',
      type: 'seguro',
      value: (totais?.seguro || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Desconto',
      type: 'desconto_vlr',
      value: (totais?.desconto || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Despesas Acessórias',
      type: 'despac',
      value: (totais?.despac || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Impostos',
      type: 'impostos',
      value: (totais?.impostos || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Eventuais',
      type: 'eventuais',
      value: (totais?.eventuais || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Frete',
      type: 'frete',
      value: (totais?.frete || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Outros',
      type: 'outros',
      value: (totais?.outros || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
  ];

  const Line = ({ value, label, type }) => (
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center">
        <Typography
          flex={1}
          variant="body2"
          fontWeight="bold"
          sx={type && !disableActions ? styles?.link : {}}
          onClick={() =>
            !disableActions &&
            (type === 'impostos'
              ? openDialog(<ImpostosModal documento={documento} />, 'Impostos')
              : openDialog(
                  <AverageModal
                    type={type}
                    onSubmit={onSubmit}
                    documento={documento}
                  />,
                  'Outros Valores'
                ))
          }
        >
          {label}:
        </Typography>
        <Typography flex={3} variant="body2">
          {value}
        </Typography>
      </Box>
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card title="Itens">
          <Grid container spacing={2}>
            {!disableActions && (
              <Grid item xs={12} textAlign="center">
                <ButtonMenu
                  buttonProps={{ variant: 'outlined', loading }}
                  options={options}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ItensGrid
                itens={itens}
                actions={actions}
                loading={loading}
                checkboxSelection
                documento={documento}
                selectionModel={selected?.map((s) => s?.id)}
                onSelectionModelChange={(ids) =>
                  setSelected(
                    ids?.map((id) => itens?.find((f) => f?.id === id))
                  )
                }
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="Totais">
          <Grid container spacing={2}>
            {lines?.map((props, index) => (
              <Line {...props} key={index?.toString()} />
            ))}
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Total:{' '}
                {(totais?.documento || 0)?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Itens;
