import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment';

const StatusCell = ({ row = {} }) => {
  const color = {
    PENDENTE: '#FFFF7F',
    CONCLUIDO: '#7FFF7F',
    CANCELADO: '#FF7F7F',
  };
  const sx = { flex: 1, backgroundColor: color[row?.status] };
  return (
    <Box flex={1} textAlign="center" display="flex">
      <Chip label={row?.situacao} size="small" sx={sx} />
    </Box>
  );
};

const DocumentoCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Valor',
      value: (row?.vldocto || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Data de Emissão',
      value:
        moment(row?.dtemissao).isValid() &&
        moment(row?.dtemissao).format('DD/MM/YYYY [às] HH:mm'),
    },
    {
      label: 'Prazo de Entrega',
      value:
        moment(row?.przent).isValid() &&
        moment(row?.przent).format('DD/MM/YYYY'),
    },
  ];
  const att =
    Boolean(row?.dtmodificacao) && moment(row?.dtmodificacao).isValid()
      ? moment(row?.dtmodificacao).format('DD/MM/YYYY [às] HH:mm')
      : null;
  const duration = moment
    .duration(moment().diff(moment(row?.dtmodificacao)))
    .asDays();
  const color = duration > 10 ? 'red' : duration >= 5 ? 'orange' : null;

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Typography fontWeight="bold" mb={1} variant="body1">
        Orçamento {row?.documento || ''}
      </Typography>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
      {Boolean(att) && (
        <Box flex={1} textAlign="end">
          <Typography color={color} variant="caption">
            Última Modificação em {att}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const ClienteCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Contato',
      value: row?.contato,
    },
  ];
  return (
    <Box flex={1}>
      <Typography fontWeight="bold" mb={1} variant="body1">
        {row?.cliente}
      </Typography>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

const EquipamentoCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Localização',
      value: row?.localizacao,
    },
  ];
  return (
    <Box flex={1}>
      <Typography fontWeight="bold" mb={1} variant="body1">
        {row?.equipamento}
      </Typography>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

const ManutencaoCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Atendente',
      value: row?.atendente,
    },
    {
      label: 'Técnico',
      value: row?.tecnico,
    },
  ];
  return (
    <Box flex={1}>
      <Typography fontWeight="bold" mb={1} variant="body1">
        {row?.manutencao}
      </Typography>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

const mock = [
  {
    field: 'status',
    renderCell: (props) => <StatusCell {...props} />,
  },
  {
    field: 'documento',
    renderCell: (props) => <DocumentoCell {...props} />,
  },
  {
    field: 'cliente',
    renderCell: (props) => <ClienteCell {...props} />,
  },
  {
    field: 'manutencao',
    renderCell: (props) => <ManutencaoCell {...props} />,
  },
  {
    field: 'equipamento',
    renderCell: (props) => <EquipamentoCell {...props} />,
  },
];

export default mock;
