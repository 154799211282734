import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FileOpen } from '@mui/icons-material';
import { openXML } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import moment from 'moment';

const Cartas = ({ onSubmit, documento = {}, gridProps = {} }) => {
  const { openDialog } = useDialog();
  const cartas = documento?.DocumentoCces || [];

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      sequencia: documento?.DocumentoCces?.length + 1,
      carta: item?.carta || '',
    };
    const { control, watch, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            A Carta de Correção é disciplinada pelo § 1º-A do art. 7º do
            Convênio S/N, de 15 de dezembro de 1970 e pode ser utilizada para
            regularização de erro ocorrido na emissão de documento fiscal, desde
            que o erro não esteja relacionado com:
          </Typography>
          <Typography variant="body1">
            I - as variáveis que determinam o valor do imposto tais como: base
            de cálculo, alíquota, diferença de preço, quantidade, valor da
            operação ou da prestação;
          </Typography>
          <Typography variant="body1">
            II - a correção de dados cadastrais que implique mudança do
            remetente ou do destinatário;
          </Typography>
          <Typography variant="body1">
            III - a data de emissão ou de saída;
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            name="carta"
            control={control}
            label="Carta de Correção"
            multiline
            rows={7}
            inputProps={{ maxLength: 1000 }}
          />
          <Typography variant="body1" textAlign="end">
            {watch('carta')?.length || 0}/1000
          </Typography>
        </Grid>

        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Cces: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Cartas de Correção">
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => openDialog(<Modal />, 'Adicionar Registro')}
          >
            Adicionar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={cartas}
            columns={[
              {
                field: 'sequencia',
                headerName: 'Sequência',
                width: 100,
                sortable: false,
              },
              {
                field: 'envio',
                headerName: 'Data de Envio',
                width: 200,
                sortable: false,
                valueFormatter: ({ value }) =>
                  value && moment(value).format('DD/MM/YYYY [às] HH:mm'),
              },
              {
                field: 'carta',
                headerName: 'Carta',
                width: 550,
                sortable: false,
              },
              {
                field: 'protocolo',
                headerName: 'Protocolo',
                width: 200,
                sortable: false,
              },
              {
                field: 'ccestatus',
                headerName: 'Status',
                width: 100,
                sortable: false,
              },
              {
                field: 'xmlenvio',
                headerName: 'XML de Envio',
                align: 'center',
                headerAlign: 'center',
                width: 150,
                sortable: false,
                renderCell: ({ value }) =>
                  Boolean(value) && (
                    <IconButton size="small" onClick={() => openXML(value)}>
                      <FileOpen />
                    </IconButton>
                  ),
              },
              {
                field: 'xmlretorno',
                headerName: 'XML de Retorno',
                align: 'center',
                headerAlign: 'center',
                width: 150,
                sortable: false,
                renderCell: ({ value }) =>
                  Boolean(value) && (
                    <IconButton size="small" onClick={() => openXML(value)}>
                      <FileOpen />
                    </IconButton>
                  ),
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Cartas;
