import { createContext, useContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: [],
  situacao: [],
  documento: '',
  valor: '',
  dtemissao: { i: null, f: null },
  przent: { i: null, f: null },
  dtmodificacao: { i: null, f: null },
  cliente: [],
  contato: '',
  manutencao: [],
  atendente: [],
  tecnico: [],
  equipamento: [],
  localizacao: '',
};

export const ServicosContext = createContext();

export const ServicosProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [servicos, setServicos] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const getServicos = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: servicos?.filter,
        page: servicos?.page,
        size: servicos?.size,
        order: servicos?.order,
        ...payload,
      });
      setServicos((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const exportOrdens = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Servicos/Ordens/Exportar', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postFatura = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const { data: res } = await api.post('/Servicos/Ordens/Gerar', data);
      if (cb) {
        cb();
      }
      if ((res?.urls || [])?.length) {
        res?.urls?.map((url, i) =>
          setTimeout(() => window.open(url), 1000 * i)
        );
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ServicosContext.Provider
      value={{
        servicos,
        getLoading,
        postLoading,
        getServicos,
        exportOrdens,
        postFatura,
      }}
    >
      {children}
    </ServicosContext.Provider>
  );
};
