import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import InputMask from 'components/InputMask';
import moment from 'moment';
import styles from './styles';

const Registros = ({
  user,
  onSubmit,
  onDelete,
  onDeleteAnexo,
  onSubmitAnexo,
  documento = {},
  gridProps = {},
  loading = false,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoHist';
  const registros = documento?.DocumentoHists || [];

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      users_id: user?.id,
      datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
      inicio: item?.inicio
        ? moment(item?.inicio).format('YYYY-MM-DD HH:mm:ss')
        : null,
      fim: item?.fim ? moment(item?.fim).format('YYYY-MM-DD HH:mm:ss') : null,
      historico: item?.historico || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="inicio"
            control={control}
            label="Início"
            type="datetime"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="fim" control={control} label="Fim" type="datetime" />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="historico"
            control={control}
            label="Registro"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Registros: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const Anexos = ({ row }) => {
    const midias = row?.DocumentoHistmids?.map((m) => ({
      id: m?.id,
      documento_hist_id: m?.documento_hist_id,
      midia_id: m?.midia_id,
      url: m?.Midium?.url,
      nomeoriginal: m?.Midium?.nomeoriginal,
    }));

    const onAddAnexo = () => document.getElementById('upload-midia')?.click();

    const handleSubmitAnexo = ({ target }) => {
      const file = target.files[0];
      target.value = '';
      const formData = new FormData();
      formData.append('id', row?.id);
      formData.append('midia', file);
      onSubmitAnexo(formData);
    };

    return (
      <Box sx={styles?.container}>
        {midias?.map((m) => (
          <Chip
            key={m?.id?.toString()}
            label={m?.nomeoriginal}
            size="small"
            color="primary"
            variant="outlined"
            deleteIcon={<Delete />}
            onClick={() => window.open(m?.url)}
            onDelete={() => onDeleteAnexo(m?.midia_id)}
            sx={styles?.chip}
          />
        ))}
        {!Boolean(row?.status_id) && (
          <IconButton
            size="small"
            color="primary"
            onClick={onAddAnexo}
            disabled={loading}
          >
            <Add fontSize="small" />
          </IconButton>
        )}
        <input
          id="upload-midia"
          type="file"
          hidden
          onChange={handleSubmitAnexo}
        />
      </Box>
    );
  };

  return (
    <Card title="Registros">
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => openDialog(<Modal />, 'Adicionar Registro')}
            loading={loading}
          >
            Adicionar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={registros}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                width: 100,
                getActions: ({ row, id }) =>
                  Boolean(row?.status_id)
                    ? []
                    : [
                        <GridActionsCellItem
                          icon={<Edit />}
                          label="Editar"
                          onClick={() =>
                            openDialog(<Modal item={row} />, 'Editar Registro')
                          }
                        />,
                        <GridActionsCellItem
                          icon={<Delete />}
                          label="Excluir"
                          onClick={() => onDelete({ rotina, id })}
                        />,
                      ],
              },
              {
                field: 'historico',
                headerName: 'Registro',
                width: 500,
                sortable: false,
              },
              {
                field: 'entidade',
                headerName: 'Entidade',
                width: 500,
                sortable: false,
                valueGetter: ({ row }) => row?.User?.name || '',
              },
              {
                field: 'datahora',
                headerName: 'Data / Hora',
                width: 200,
                sortable: false,
                valueFormatter: ({ value }) =>
                  value && moment(value).format('DD/MM/YYYY [às] HH:mm'),
              },
              {
                field: 'anexos',
                headerName: 'Anexos',
                width: 400,
                sortable: false,
                renderCell: (props) => <Anexos {...props} />,
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Registros;
