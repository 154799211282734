import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FileOpen } from '@mui/icons-material';
import { openXML } from 'utils/functions';
import Card from 'components/Card';
import moment from 'moment';

const Transmissão = ({ documento = {}, gridProps = {} }) => {
  const status = documento?.DocumentoNfeServicos || [];
  return (
    <Card title="Status da Transmissão">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGrid
            rows={status}
            columns={[
              {
                field: 'dtrecebimento',
                headerName: 'Data de Recebimento',
                width: 200,
                sortable: false,
                valueFormatter: ({ value }) =>
                  value && moment(value).format('DD/MM/YYYY [às] HH:mm'),
              },
              {
                field: 'nfe_status',
                headerName: 'Status',
                width: 450,
                sortable: false,
              },
              {
                field: 'motivo',
                headerName: 'Motivo',
                width: 450,
                sortable: false,
              },
              {
                field: 'xmlenvio',
                headerName: 'XML de Envio',
                align: 'center',
                headerAlign: 'center',
                width: 150,
                sortable: false,
                renderCell: ({ value }) =>
                  Boolean(value) && (
                    <IconButton size="small" onClick={() => openXML(value)}>
                      <FileOpen />
                    </IconButton>
                  ),
              },
              {
                field: 'xmlretorno',
                headerName: 'XML de Retorno',
                align: 'center',
                headerAlign: 'center',
                width: 150,
                sortable: false,
                renderCell: ({ value }) =>
                  Boolean(value) && (
                    <IconButton size="small" onClick={() => openXML(value)}>
                      <FileOpen />
                    </IconButton>
                  ),
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Transmissão;
