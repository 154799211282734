import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: [],
  situacao: [],
  documento: '',
  valor: '',
  dtemissao: { i: null, f: null },
  przent: { i: null, f: null },
  dtmodificacao: { i: null, f: null },
  cliente: [],
  contato: '',
  manutencao: [],
  atendente: [],
  tecnico: [],
  equipamento: [],
  localizacao: '',
};

export const OrçamentosContext = createContext();

export const OrçamentosProvider = ({ children }) => {
  const [postLoading, setPostLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [orçamentos, setOrçamentos] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const getOrçamentos = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: orçamentos?.filter,
        page: orçamentos?.page,
        size: orçamentos?.size,
        order: orçamentos?.order,
        ...payload,
      });
      setOrçamentos((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postServico = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Comercial/Contratos/Servico', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <OrçamentosContext.Provider
      value={{
        orçamentos,
        getLoading,
        postLoading,
        postServico,
        getOrçamentos,
      }}
    >
      {children}
    </OrçamentosContext.Provider>
  );
};
