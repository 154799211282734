import { useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useModal } from 'components/Modals';
import { Controller, useForm } from 'react-hook-form';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Button from 'components/Button';

const ConclusaoModal = ({ onSubmit, item = {} }) => {
  const defaultValues = { status_id: 6 };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(DocumentosContext);

  const options = [
    { key: '1', value: 6, label: 'Somente Concluir' },
    {
      key: '2',
      value: 20,
      label: 'Liberar para Faturamento',
      disabled: Boolean(item?.vldocto <= 0) || item?.manutencao === 'GARANTIA',
    },
    {
      key: '3',
      value: 26,
      label: 'Devolver Equipamento',
      disabled: !Boolean(item?.equipamento_id),
    },
    {
      key: '4',
      value: 27,
      label: 'Descartar Equipamento',
      disabled: !Boolean(item?.equipamento_id),
    },
  ];
  return (
    <>
      <DialogTitle>Conclusão do Serviço</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Controller
              control={control}
              name="status_id"
              render={({ field }) => (
                <RadioGroup {...field}>
                  {options?.map((t) => (
                    <FormControlLabel {...t} control={<Radio />} />
                  ))}
                </RadioGroup>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Confirmar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default ConclusaoModal;
