const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    flex: 1,
    mr: 1,
  },
  lineContainer: {
    flex: 1,
  },
  line: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lineTitle: {
    mb: 1,
    flex: 1,
    fontWeight: 'bold',
  },
  lineLabel: {
    flex: 2,
    fontWeight: 'bold',
  },
  lineValue: {
    flex: 3,
  },
  popover: {
    p: [1],
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: 400,
  },
};

export default styles;
