import { createContext, useState } from 'react';
import api from 'services/api';

export const VendaContext = createContext();

export const VendaProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [contas, setContas] = useState({});

  const getContas = async () => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Comercial/Venda');
      setContas(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <VendaContext.Provider value={{ getContas, getLoading, contas }}>
      {children}
    </VendaContext.Provider>
  );
};
