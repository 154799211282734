import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { PlayArrow, Settings, Stop } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { decryptURL, summarizer } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import PauseModal from './Modals/PauseModal';
import EditModal from './Modals/EditModal';
import styles from './styles';
import moment from 'moment';

const Apontamento = () => {
  const rotina = 'DocumentoItemProcessoApont';
  const { hash } = useParams();
  const { openModal, closeModal } = useModal();
  const { user } = useContext(AuthContext);
  const { getLoading, getDocumento } = useContext(DocumentosContext);
  const { deleteGrid, deleteLoading, postGrid, postLoading } =
    useContext(GridContext);
  const [processos, setProcessos] = useState([]);
  const [selected, setSelected] = useState(null);
  const decrypt = decryptURL(hash);
  const processo = processos?.find((p) => p?.id === selected);
  const apontamentos = processo?.DocumentoItemProcessoAponts || [];
  const iniciado = apontamentos?.find(
    (f) =>
      Boolean(f?.executante_id === user?.id) &&
      Boolean(f?.inicio) &&
      !Boolean(f?.fim)
  );
  const tempos = apontamentos?.map((a) => {
    return {
      executante_id: a?.executante_id,
      tempo_total:
        Boolean(a?.inicio) && Boolean(a?.fim)
          ? moment
              .duration(moment(a?.fim).diff(moment(a?.inicio)))
              .asMilliseconds()
          : 0,
    };
  });

  const getTotal = (arr) => {
    const total = summarizer(arr, 'tempo_total');
    return (
      moment.duration(total).days() +
      'd: ' +
      moment.duration(total).hours() +
      'h: ' +
      moment.duration(total).minutes() +
      'm: ' +
      moment.duration(total).seconds() +
      's '
    );
  };

  const totais = [
    {
      value: getTotal(tempos),
      label: 'Horas apontadas',
    },
    {
      value: getTotal(tempos?.filter((f) => f?.executante_id === user?.id)),
      label: 'Horas de ' + (user?.name || ''),
    },
    {
      value:
        (summarizer(apontamentos, 'quantidade') || 0) +
        ` ${processo?.um || ''}`,
      label: 'Total produzido',
    },
    {
      value:
        (summarizer(apontamentos, 'perda') || 0) + ` ${processo?.um || ''}`,
      label: 'Total perdido',
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    closeModal();
    getDocumento(decrypt?.id, (v) => {
      const procs = [];
      v?.DocumentoItems?.map((di) => {
        di?.DocumentoItemProcessos?.map((p) => {
          procs.push({
            ...p,
            um: di?.UnidadeMedida?.sigla,
            quantidade: di?.quantidade,
            item: di?.descricao,
          });
        });
      });
      setProcessos(procs);
    });
  };

  const postData = (data) => postGrid({ data, rotina, cb: loadData });

  const handleApontamento = () => {
    const data = {
      documento_item_processo_id: selected,
      executante_id: user?.id,
      inicio: iniciado?.inicio || moment().format('YYYY-MM-DD HH:mm:ss'),
      users_id: user?.id,
    };
    if (Boolean(iniciado)) {
      const onSubmit = (values) => {
        data['id'] = iniciado?.id;
        data['quantidade'] = values?.quantidade || 0;
        data['perda'] = values?.perda || 0;
        data['fim'] = moment().format('YYYY-MM-DD HH:mm:ss');
        postData(data);
      };
      openModal(<PauseModal onSubmit={onSubmit} />);
    } else {
      postData(data);
    }
  };

  const onEdit = (item) => {
    openModal(<EditModal item={item} onSubmit={postData} />);
  };

  const onDelete = (id) => {
    const params = { rotina, id };
    openModal(
      <DeleteModal onSubmit={() => deleteGrid({ params, cb: loadData })} />
    );
  };

  const TempoCell = ({ row }) => {
    const duration = moment.duration(
      moment(row?.fim).diff(moment(row?.inicio))
    );
    const lines = [
      {
        label: 'Início',
        value:
          moment(row?.inicio).isValid() &&
          moment(row?.inicio).format('DD/MM/YYYY [às] HH:mm'),
      },
      {
        label: 'Fim',
        value:
          moment(row?.fim).isValid() &&
          moment(row?.fim).format('DD/MM/YYYY [às] HH:mm'),
      },
      {
        label: 'Total',
        value:
          Boolean(row?.inicio) &&
          Boolean(row?.fim) &&
          `${duration?.days()}d: ${duration?.hours()}h: ${duration?.minutes()}m: ${duration?.seconds()}s`,
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const QuantidadeCell = ({ row }) => {
    const lines = [
      {
        label: 'Produzida',
        value: (row?.quantidade || 0) + ` ${processo?.um || ''}`,
      },
      {
        label: 'Perda',
        value: (row?.perda || 0) + ` ${processo?.um || ''}`,
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Apontamento de Horas" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Card title="Processos" style={styles?.card}>
            <Box display="flex">
              {Boolean(processos?.length) ? (
                <List style={styles?.card}>
                  {processos?.map((p, i) => {
                    const apontamentos = p?.DocumentoItemProcessoAponts;
                    const qtd = summarizer(apontamentos, 'quantidade') || 0;
                    const perda = summarizer(apontamentos, 'perda') || 0;
                    const total = p.quantidade || 1;
                    const progress = ((qtd + perda) / total) * 100;

                    return (
                      <ListItemButton
                        divider={i + 1 < processos?.length}
                        key={`${p?.id}`}
                        selected={selected === p?.id}
                        onClick={() => setSelected(p?.id)}
                      >
                        <ListItemIcon>
                          <Settings />
                        </ListItemIcon>
                        {console.log(p)}
                        <ListItemText
                          primary={p?.descricao}
                          secondary={p?.item}
                          sx={styles?.progress}
                        />
                        <Box display="flex" flex={1}>
                          <Box sx={styles?.line}>
                            <LinearProgress
                              variant="determinate"
                              value={progress}
                              sx={styles?.progress}
                              color="info"
                            />
                            <Typography
                              variant="body2"
                              flex={1}
                              textAlign="end"
                            >
                              {progress}%
                            </Typography>
                          </Box>
                        </Box>
                      </ListItemButton>
                    );
                  })}
                </List>
              ) : (
                <Typography variant="caption" textAlign="center" flex={1}>
                  Nenhum processo encontrado
                </Typography>
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={8} display="flex">
          <Card title="Apontamentos" style={styles?.card}>
            <Grid container spacing={2}>
              {!Boolean(selected) && (
                <Grid item xs={12} textAlign="center">
                  <Typography variant="caption">
                    Nenhum processo selecionado
                  </Typography>
                </Grid>
              )}
              {Boolean(selected) && (
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant={Boolean(iniciado) ? 'outlined' : 'contained'}
                    startIcon={Boolean(iniciado) ? <Stop /> : <PlayArrow />}
                    onClick={handleApontamento}
                  >
                    Apontamento
                  </Button>
                </Grid>
              )}
              {Boolean(selected) && (
                <Grid item xs={12}>
                  <DataGrid
                    rows={apontamentos}
                    columns={[
                      {
                        field: 'executante',
                        headerName: 'Executante',
                        flex: 2,
                        sortable: false,
                        valueGetter: ({ row }) => row?.User?.name,
                      },
                      {
                        field: 'tempo',
                        headerName: 'Tempo',
                        flex: 1,
                        sortable: false,
                        renderCell: (props) => <TempoCell {...props} />,
                      },
                      {
                        field: 'quantidade',
                        headerName: 'Quantidade',
                        flex: 1,
                        sortable: false,
                        renderCell: (props) => <QuantidadeCell {...props} />,
                      },
                      {
                        field: 'actions',
                        headerName: 'Ações',
                        type: 'actions',
                        width: 100,
                        getActions: ({ id, row }) =>
                          row?.executante_id !== user?.id
                            ? []
                            : [
                                <GridActionsCellItem
                                  icon={<Icon>edit</Icon>}
                                  label="Editar"
                                  onClick={() => onEdit(row)}
                                />,
                                <GridActionsCellItem
                                  icon={<Icon>delete</Icon>}
                                  label="Excluir"
                                  onClick={() => onDelete(id)}
                                />,
                              ],
                      },
                    ]}
                    loading={deleteLoading || getLoading || postLoading}
                    hideFooter
                    autoHeight
                    density="compact"
                    disableSelectionOnClick
                    disableColumnMenu
                    showCellRightBorder
                    showColumnRightBorder
                    localeText={localeText}
                    getRowHeight={() => 'auto'}
                  />
                </Grid>
              )}
              {Boolean(selected) && (
                <Grid item xs={12}>
                  {totais?.map(({ label, value }) => (
                    <Box key={label} display="flex" flex={1}>
                      <Typography variant="body2" fontWeight="bold" flex={1}>
                        {label}:
                      </Typography>
                      <Typography variant="body2" flex={2}>
                        {value}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Apontamento;
