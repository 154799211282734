const styles = {
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginTop: '1em',
  },
};

export default styles;
