import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { dropTipoPessoa } from 'utils/drops';
import { findOnArray } from 'utils/functions';
import { EntidadesContext } from 'contexts/EntidadesContext';
import { DropsContext } from 'contexts/DropsContext';
import { useDialog, useModal } from 'components/Modals';
import PesquisaEnderecoModal from 'components/Modals/PesquisaEnderecoModal';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Input from 'components/Input';

const EntidadeModal = ({ entidade_id, callback }) => {
  const defaultValues = {
    pessoa: 'FISICA',
    documento: '',
    documento_sec: '',
    nome: '',
    fone: '',
    celular: '',
    email: '',
    cep: '',
    estado: null,
    cidade: null,
    bairro: '',
    logradouro: '',
    numero: '',
    complemento: '',
  };
  const [entidade, setEntidade] = useState(null);
  const { closeDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { drops, getDrops } = useContext(DropsContext);
  const {
    postEntidade,
    postLoading,
    entidade: entCtx,
    getLoading,
    getEntidade,
    setEntidade: setEntCtx,
  } = useContext(EntidadesContext);
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (entidade_id) {
      getEntidade(entidade_id);
    }
  }, []);

  useEffect(() => {
    if (entCtx) {
      setEntidade(entCtx);
      setEntCtx(null);
    }
  }, [entCtx]);

  useEffect(() => {
    if (entidade && entidade_id) {
      if (Boolean(entidade?.pessoa)) setValue('pessoa', entidade?.pessoa);
      if (entidade?.pessoa === 'FISICA') {
        const documento = entidade?.EntidadeDocumentos?.find(
          (d) => d?.tipo_documento_id === 2
        )?.documento;
        if (Boolean(documento)) setValue('documento', documento);
        const documento_sec = entidade?.EntidadeDocumentos?.find(
          (d) => d?.tipo_documento_id === 4
        )?.documento;
        if (Boolean(documento_sec)) setValue('documento_sec', documento_sec);
      }
      if (entidade?.pessoa === 'JURIDICA') {
        const documento = entidade?.EntidadeDocumentos?.find(
          (d) => d?.tipo_documento_id === 1
        )?.documento;
        if (Boolean(documento)) setValue('documento', documento);
        const documento_sec = entidade?.EntidadeDocumentos?.find(
          (d) => d?.tipo_documento_id === 3
        )?.documento;
        if (Boolean(documento_sec)) setValue('documento_sec', documento_sec);
      }
      if (Boolean(entidade?.nome)) setValue('nome', entidade?.nome);

      const fone = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 1
      )?.conteudo;
      if (Boolean(fone)) setValue('fone', fone);

      const celular = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 2
      )?.conteudo;
      if (Boolean(celular)) setValue('celular', celular);

      const email = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 3
      )?.conteudo;
      if (Boolean(email)) setValue('email', email);

      const endereco = entidade?.EntidadeEnderecos?.find(
        (e) => e?.tipo_endereco_id === 1
      );
      if (Boolean(endereco)) {
        if (Boolean(endereco?.cep)) setValue('cep', endereco?.cep);
        if (Boolean(endereco?.Cidade?.uf_id))
          setValue('estado', endereco?.Cidade?.uf_id);
        if (Boolean(endereco?.cidade_id))
          setValue('cidade', endereco?.cidade_id);
        if (Boolean(endereco?.bairro)) setValue('bairro', endereco?.bairro);
        if (Boolean(endereco?.logradouro))
          setValue('logradouro', endereco?.logradouro);
        if (Boolean(endereco?.numero)) setValue('numero', endereco?.numero);
        if (Boolean(endereco?.complemento))
          setValue('complemento', endereco?.complemento);
      }
    }
  }, [entidade]);

  const onSearchAddress = (endereco) => {
    const estado = drops?.UF?.find((u) => u?.sigla === endereco?.uf)?.value;
    const cidade = drops?.Cidade?.find(
      (u) => u?.codigo_ibge === Number(endereco?.ibge)
    )?.value;
    if (Boolean(endereco?.cep)) {
      setValue('cep', endereco?.cep);
    }
    if (Boolean(estado)) {
      setValue('estado', estado);
    }
    if (Boolean(cidade)) {
      setValue('cidade', cidade);
    }
    if (Boolean(endereco?.bairro)) {
      setValue('bairro', endereco?.bairro);
    }
    if (Boolean(endereco?.logradouro)) {
      setValue('logradouro', endereco?.logradouro);
    }
    if (Boolean(endereco?.complemento)) {
      setValue('complemento', endereco?.complemento);
    }
    closeModal();
  };

  const onSubmit = (values) => {
    const Documentos = [];
    const Contatos = [];
    const Enderecos = [];
    const Perfis = [];

    if (!entidade_id) {
      Perfis?.push({
        // entidade_tributacao_id: null,
        tipo: 'CLIENTE',
      });
    }

    const documento =
      entidade?.pessoa === 'FISICA'
        ? entidade?.EntidadeDocumentos?.find((d) => d?.tipo_documento_id === 2)
        : entidade?.pessoa === 'JURIDICA'
        ? entidade?.EntidadeDocumentos?.find((d) => d?.tipo_documento_id === 1)
        : null;
    if (Boolean(documento) || Boolean(values?.documento)) {
      Documentos?.push({
        id: documento?.id,
        tipo_documento_id: values?.pessoa === 'FISICA' ? 2 : 1,
        documento: values?.documento,
      });
    }

    const documento_sec =
      entidade?.pessoa === 'FISICA'
        ? entidade?.EntidadeDocumentos?.find((d) => d?.tipo_documento_id === 4)
        : entidade?.pessoa === 'JURIDICA'
        ? entidade?.EntidadeDocumentos?.find((d) => d?.tipo_documento_id === 3)
        : null;
    if (Boolean(documento_sec) || Boolean(values?.documento_sec)) {
      Documentos?.push({
        id: documento_sec?.id,
        tipo_documento_id: values?.pessoa === 'FISICA' ? 4 : 3,
        documento: values?.documento_sec,
      });
    }

    const fone = entidade?.EntidadeContatos?.find(
      (c) => c?.tipo_contato_id === 1
    );
    if (Boolean(fone) || Boolean(values?.fone)) {
      Contatos?.push({
        id: fone?.id,
        tipo_contato_id: 1,
        conteudo: values?.fone,
      });
    }
    const celular = entidade?.EntidadeContatos?.find(
      (c) => c?.tipo_contato_id === 2
    );
    if (Boolean(celular) || Boolean(values?.celular)) {
      Contatos?.push({
        id: celular?.id,
        tipo_contato_id: 2,
        conteudo: values?.celular,
      });
    }
    const email = entidade?.EntidadeContatos?.find(
      (c) => c?.tipo_contato_id === 3
    );
    if (Boolean(email) || Boolean(values?.email)) {
      Contatos?.push({
        id: email?.id,
        tipo_contato_id: 3,
        conteudo: values?.email,
      });
    }

    const endereco = entidade?.EntidadeEnderecos?.find(
      (e) => e?.tipo_endereco_id === 1
    );
    if (
      Boolean(endereco) ||
      Boolean(values?.cep) ||
      Boolean(values?.cidade) ||
      Boolean(values?.bairro) ||
      Boolean(values?.logradouro) ||
      Boolean(values?.numero) ||
      Boolean(values?.complemento)
    ) {
      Enderecos?.push({
        id: endereco?.id,
        tipo_endereco_id: 1,
        cep: values?.cep,
        cidade_id: values?.cidade,
        bairro: values?.bairro,
        logradouro: values?.logradouro,
        numero: values?.numero,
        complemento: values?.complemento,
        principal: 'SIM',
      });
    }

    const data = {
      Entidade: {
        id: entidade_id,
        pessoa: values?.pessoa,
        nome: values?.nome,
        fantasia: values?.nome,
      },
      Documentos,
      Contatos,
      Enderecos,
      Perfis,
    };

    postEntidade({
      data,
      cb: ({ id }) => {
        closeDialog();
        getDrops(['Entidade']);
        if (callback) {
          callback(id);
        }
      },
    });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações Gerais
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="pessoa"
          control={control}
          label="Pessoa"
          options={dropTipoPessoa}
          onValueChange={() => {
            resetField('documento');
            resetField('documento_sec');
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="documento"
          control={control}
          label={watch('pessoa') === 'FISICA' ? 'CPF' : 'CNPJ'}
          mask={
            watch('pessoa') === 'FISICA'
              ? '999.999.999-99'
              : '99.999.999/9999-99'
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="documento_sec"
          control={control}
          label={watch('pessoa') === 'FISICA' ? 'RG' : 'IE'}
          mask={
            watch('pessoa') === 'FISICA'
              ? '99.999.999-9'
              : '999999999999999999999'
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Input name="nome" control={control} label="Nome / Razão Social" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Contatos
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="fone"
          control={control}
          label="Telefone Fixo"
          mask="(99) 9999-9999"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="celular"
          control={control}
          label="Celular"
          mask="(99) 99999-9999"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input name="email" control={control} label="E-mail" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Endereço
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            openModal(<PesquisaEnderecoModal onSubmit={onSearchAddress} />, 90)
          }
        >
          Pesquisar Endereço
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputMask name="cep" control={control} label="CEP" mask="99999-999" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Dropdown
          name="estado"
          control={control}
          label="Estado"
          options={drops?.UF}
          onValueChange={() => resetField('cidade')}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Dropdown
          name="cidade"
          control={control}
          label="Cidade"
          options={drops?.Cidade?.filter((c) => {
            const estado = findOnArray(watch('estado'), drops?.UF);
            return estado?.sigla === c?.sigla;
          })}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Input name="bairro" control={control} label="Bairro" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input name="logradouro" control={control} label="Endereço" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Input name="numero" control={control} label="Número" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Input name="complemento" control={control} label="Complemento" />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default EntidadeModal;
