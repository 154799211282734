const styles = {
  cardContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    flex: 1,
  },
  line: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineLabel: (flex = 0, link = false, wrap = false) => ({
    flex,
    fontWeight: 'bold',
    cursor: link ? 'pointer' : 'auto',
    whiteSpace: wrap ? 'nowrap' : 'normal',
    textDecoration: link ? 'underline' : 'none',
  }),
  lineValue: (flex = 0, link = false, wrap = false) => ({
    flex,
    cursor: link ? 'pointer' : 'auto',
    whiteSpace: wrap ? 'nowrap' : 'normal',
    textDecoration: link ? 'underline' : 'none',
  }),
  popover: {
    p: [1],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
  },
};

export default styles;
