import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { encryptURL } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import VirtualDrop from 'components/VirtualDrop';

const Equipamentos = ({
  onSubmit,
  onDelete,
  drops = [],
  documento = {},
  gridProps = {},
  disableActions = false,
}) => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const rotina = 'DocumentoEquipamento';
  const equipamentos = documento?.DocumentoEquipamentos || [];

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      sn: item?.sn || '',
      equipamento_id: item?.equipamento_id || null,
      relatocli: item?.relatocli || '',
      relatotec: item?.relatotec || '',
      acessorios: item?.acessorios || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="sn" control={control} label="Serial Number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VirtualDrop
            name="equipamento_id"
            control={control}
            label="Equipamento"
            options={drops?.Equipamento}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="relatocli"
            control={control}
            label="Relato do Cliente"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="relatotec"
            control={control}
            label="Relato do Técnico"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="acessorios"
            control={control}
            label="Observação / Acessórios"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Equipamentos: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const EquipamentoCell = ({ row = {} }) => {
    const lines = [
      {
        label: 'Serial Number',
        value: row?.sn,
      },
      {
        label: 'Referência',
        value: row?.Equipamento?.referencia,
      },
      {
        label: 'Marca',
        value: row?.Cemarca?.marca,
      },
      {
        label: 'Modelo',
        value: row?.Modelo?.modelo,
      },
      {
        label: 'Alimentação',
        value: row?.Equipamento?.alimentacao,
      },
    ];
    return (
      <Box flex={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {row?.Equipamento?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={4} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  return (
    <Card title="Equipamentos">
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Registro')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={equipamentos}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                width: disableActions ? 100 : 150,
                getActions: ({ row, id }) => {
                  const arr = [
                    <GridActionsCellItem
                      icon={<Visibility />}
                      label="Detalhes"
                      onClick={() =>
                        navigate(
                          `/app/Documento/Equipamento/${encryptURL(row)}`
                        )
                      }
                    />,
                  ];
                  if (!disableActions) {
                    arr?.push(
                      <GridActionsCellItem
                        icon={<Edit />}
                        label="Editar"
                        onClick={() =>
                          openDialog(<Modal item={row} />, 'Editar Registro')
                        }
                      />,
                      <GridActionsCellItem
                        icon={<Delete />}
                        label="Excluir"
                        onClick={() => onDelete({ rotina, id })}
                      />
                    );
                  }
                  return arr;
                },
              },
              {
                field: 'equipamento',
                headerName: 'Equipamento',
                flex: 1,
                sortable: false,
                renderCell: (props) => <EquipamentoCell {...props} />,
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Equipamentos;
