import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { findOnArray } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';

const Variações = ({
  onSubmit,
  onDelete,
  item = {},
  drops = [],
  gridProps = {},
  loading = false,
  disableActions = false,
}) => {
  const { openModal, closeModal } = useModal();
  const rotina = 'DocumentoItemVariacao';
  const variacoes = item?.DocumentoItemVariacaos || [];
  const drop = drops?.ProdutoVariacao?.filter(
    (f) => f?.produto_id === item?.produto_id
  );

  const Modal = ({ variacao }) => {
    const defaultValues = {
      id: variacao?.id,
      documento_item_id: item?.id,
      variacao_id: variacao?.variacao_id || null,
      quantidade: variacao?.quantidade || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);
    const title = Boolean(variacao) ? 'Editar Variação' : 'Adicionar Variação';

    const onConfirm = (values) => {
      const data = {
        Itens: [
          {
            id: item?.id,
            documento_id: item?.documento_id,
            Variacoes: [values],
          },
        ],
      };

      onSubmit(data);
    };

    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12} sm={8}>
              <Dropdown
                name="variacao_id"
                control={control}
                label="Variação"
                options={drop}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="quantidade"
                control={control}
                label="Quantidade"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Voltar
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Card title="Variações">
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openModal(<Modal />)}
              loading={loading}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={variacoes}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                hide: disableActions,
                width: 100,
                getActions: ({ row, id }) => [
                  <GridActionsCellItem
                    icon={<Edit />}
                    label="Editar"
                    onClick={() => openModal(<Modal variacao={row} />)}
                  />,
                  <GridActionsCellItem
                    icon={<Delete />}
                    label="Excluir"
                    onClick={() => onDelete({ rotina, id })}
                  />,
                ],
              },
              {
                field: 'variacao',
                headerName: 'Variação',
                flex: 1,
                sortable: false,
                valueGetter: ({ row }) =>
                  findOnArray(row?.variacao_id, drop, 'label'),
              },
              {
                field: 'quantidade',
                headerName: 'Quantidade',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  `${row?.quantidade || 0} ${item?.UnidadeMedida?.sigla || ''}`,
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Variações;
