import React, { useContext, useEffect } from 'react';
import { Checkbox, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import { useDialog } from '..';

const RelatorioModal = ({ relatorio }) => {
  const defaultValues = {
    visualizacao: true,
    whatsapp: false,
    email: false,
    contato: relatorio?.whatsapp || '',
    mensagem: relatorio?.mensagem || '',
    to: relatorio?.email || '',
    cc: relatorio?.cc || '',
    subject: relatorio?.assunto || '',
    text: relatorio?.mensagem || '',
  };
  const { closeDialog } = useDialog();
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });
  const { postDocumento, postLoading: docLoad } = useContext(DocumentosContext);
  const { postLoading, postRelatorio, clearRelatorio } =
    useContext(RelatoriosContext);
  const allChecked = watch('email') && watch('whatsapp');
  const loading = postLoading || docLoad;

  useEffect(() => {
    clearRelatorio();
  }, []);

  const onConfirm = (values) => {
    let data = null;
    if (Boolean(values?.visualizacao)) {
      relatorio?.urls?.map((u, i) =>
        setTimeout(() => window.open(u), 1000 * i)
      );
    }
    if (Boolean(values?.whatsapp)) {
      const link = `https://web.whatsapp.com/send?phone=${
        values?.contato
      }&text=${encodeURIComponent(values?.mensagem)}`;
      window.open(link);
    }
    if (Boolean(values?.email)) {
      data = {
        to: values?.to,
        cc: values?.cc,
        subject: values?.subject,
        text: values?.text,
        documento_id: relatorio?.documento_id,
      };
    }
    postRelatorio({
      data,
      cb: () => {
        closeDialog();
        if (Boolean(values?.whatsapp) || Boolean(values?.email)) {
          const status_id = [1, 25]?.includes(relatorio?.documento?.status_id)
            ? 17
            : null;
          const motivo = Boolean(values?.whatsapp)
            ? `Orçamento enviado via Whatsapp para ${values?.contato}`
            : Boolean(values?.email)
            ? `Orçamento enviado via E-Mail para ${values?.to}`
            : undefined;
          const data = {
            Registros: [
              { documento_id: relatorio?.documento?.id, status_id, motivo },
            ],
          };
          postDocumento({ data, cb: () => relatorio?.cb && relatorio?.cb() });
        }
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Checkbox
          color="primary"
          checked={watch('visualizacao')}
          onChange={() => setValue('visualizacao', !watch('visualizacao'))}
        />
        <Typography variant="body2" sx={{ marginLeft: 1 }}>
          Visualizar
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Checkbox
          color="primary"
          checked={watch('whatsapp')}
          onChange={() => setValue('whatsapp', !watch('whatsapp'))}
        />
        <Typography variant="body2" sx={{ marginLeft: 1 }}>
          Enviar por WhatsApp
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Checkbox
          color="primary"
          checked={watch('email')}
          onChange={() => setValue('email', !watch('email'))}
        />
        <Typography variant="body2" sx={{ marginLeft: 1 }}>
          Enviar por E-mail
        </Typography>
      </Grid>
      {Boolean(watch('whatsapp')) && (
        <Grid item xs={12} sm={allChecked ? 6 : 12}>
          <Card title="WhatsApp">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputMask
                  name="contato"
                  control={control}
                  label="Contato"
                  mask="(99) 99999-9999"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="mensagem"
                  control={control}
                  label="Mensagem"
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      {Boolean(watch('email')) && (
        <Grid item xs={12} sm={allChecked ? 6 : 12}>
          <Card title="E-mail">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input name="to" control={control} label="Para" />
              </Grid>
              <Grid item xs={12}>
                <Input name="cc" control={control} label="Cc" />
              </Grid>
              <Grid item xs={12}>
                <Input name="subject" control={control} label="Assunto" />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="text"
                  control={control}
                  label="Mensagem"
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onConfirm)}
          loading={loading}
        >
          Confirmar
        </Button>
      </Grid>
    </Grid>
  );
};

export default RelatorioModal;
