import { useState } from 'react';
import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  LinearProgress,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { Search, TrendingDown, TrendingUp } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useModal } from '..';
import localeText from 'utils/localeText';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Input from 'components/Input';
import api from 'services/api';
import moment from 'moment';

const HistoricoModal = ({
  onSubmit,
  produto_id,
  cadastro_id,
  single = false,
}) => {
  const defaultValues = {
    cadastro_id,
    produto_id,
    search: '',
    dtemissao: moment().startOf('year').format('YYYY-MM-DD'),
  };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [itens, setItens] = useState([]);
  const [toggle, setToggle] = useState(null);

  const loadData = async (values) => {
    try {
      setLoading(true);
      setSelected([]);
      const { data } = await api.post(`/Comercial/Venda/Historico`, values);
      const itens = data?.map((m) => ({
        ...m,
        ult_preco: data[0]?.preco,
        ult_desconto_vlr: data[0]?.desconto_vlr,
        ult_desconto1_pc: data[0]?.desconto1_pc,
      }));
      setItens(itens);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const DocumentoCell = ({ row = {} }) => {
    const lines = [
      {
        label: 'Nº do Documento',
        value: row?.documento,
      },
      {
        label: 'Data de Emissão',
        value:
          moment(row?.dtemissao).isValid() &&
          moment(row?.dtemissao).format('DD/MM/YYYY [às] HH:mm'),
      },
      {
        label: 'Entidade',
        value: row?.entidade,
      },
      {
        label: 'Vendedor',
        value: row?.vendedor,
      },
    ];
    const tooltip = (
      <Box whiteSpace="pre-line">
        Registro selecionado!{'\n'}Você pode alterar a quantidade, o preço e o
        desconto antes de confirmar!
      </Box>
    );

    return (
      <Tooltip title={tooltip} open={toggle === row?.id} placement="top-start">
        <Box flex={1}>
          <Typography fontWeight="bold" variant="body1" mb={1}>
            {row?.especie || ''}
            {Boolean(row?.natureza) && ` DE ${row?.natureza}`}
          </Typography>
          {lines?.map(
            ({ label, value }, i) =>
              Boolean(value) && (
                <Box key={i?.toString()} display="flex">
                  <Typography flex={1} variant="caption" fontWeight="bold">
                    {label}:{' '}
                  </Typography>
                  <Typography flex={2} variant="caption">
                    {value}
                  </Typography>
                </Box>
              )
          )}
        </Box>
      </Tooltip>
    );
  };

  const ProdutoCell = ({ row = {} }) => {
    const lines = [
      {
        label: 'Referência',
        value: row?.referencia,
      },
    ];

    return (
      <Box flex={1}>
        <Typography fontWeight="bold" variant="body1" mb={1}>
          {row?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const SaldoCell = ({ row = {} }) => {
    const color = row?.saldo > 0 ? 'success' : 'error';
    const label = row?.saldo + ' ' + (row?.unidade_medida || '');
    const icon = row?.saldo > 0 ? <TrendingUp /> : <TrendingDown />;

    return <Chip icon={icon} label={label} color={color} variant="outlined" />;
  };

  const QuantidadeCell = ({ row = {} }) => {
    return (
      <Typography variant="body2">
        {row?.quantidade || 0} {row?.unidade_medida || ''}
      </Typography>
    );
  };

  const PrecoCell = ({ row = {} }) => {
    const preco = (row?.preco || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    const last = (row?.ult_preco || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return (
      <Box flex={1} textAlign="end">
        <Typography variant="body2" fontWeight="bold">
          {preco}
        </Typography>
        {Boolean(itens[0]) && (
          <Typography variant="caption">Último preço: {last}</Typography>
        )}
      </Box>
    );
  };

  const DescontoCell = ({ row = {} }) => {
    const desconto_vlr = (row?.desconto_vlr || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    const last_vlr = (row?.ult_desconto_vlr || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    const desconto1_pc = row?.desconto1_pc || 0;
    const last_pc = row?.ult_desconto1_pc || 0;

    return (
      <Box flex={1} textAlign="end">
        <Typography variant="body2" fontWeight="bold">
          {desconto_vlr}
          {Boolean(desconto1_pc) && ` (${desconto1_pc}%)`}
        </Typography>
        {Boolean(itens[0]) && (
          <Typography variant="caption">
            Último desconto: {last_vlr}
            {Boolean(last_pc) && ` (${last_pc}%)`}
          </Typography>
        )}
      </Box>
    );
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  const onSelectionModelChange = (ids) => {
    if (ids?.length > selected?.length) {
      let [diff] = ids?.filter(
        (x) => !selected?.map((i) => i?.id)?.includes(x)
      );
      setToggle(diff);
      setTimeout(() => setToggle(null), 5000);
    }
    let arr = ids?.map((id) => itens?.find((f) => f?.id === id));
    if (single) {
      if (Boolean(arr?.length > 1)) {
        arr = arr?.slice(1, 2);
      } else {
        arr = arr?.slice(0, 1);
      }
    }
    setSelected(arr);
  };

  const onCellEditCommit = (e) => {
    const edited = itens?.map((item) => {
      if (item?.id === e?.id) {
        let desconto_vlr = item?.desconto_vlr;
        if (e?.field === 'desconto1_pc') {
          desconto_vlr =
            (e?.value || 0) * (item?.preco || 0) * (item?.quantidade || 0);
          if (desconto_vlr !== 0) {
            desconto_vlr = desconto_vlr * -0.01;
          }
        }
        return { ...item, [e?.field]: e?.value, desconto_vlr };
      } else {
        return item;
      }
    });
    setSelected(selected?.map((s) => edited?.find((f) => f?.id === s?.id)));
    setItens(edited);
  };

  const onConfirm = () => {
    const data = single ? selected[0] || null : selected;
    onSubmit(data);
  };

  return (
    <>
      <DialogTitle>
        Histórico
        {Boolean(cadastro_id) && ' da Entidade'}
        {Boolean(produto_id) && ' do Produto'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={8}>
            <Input
              name="search"
              placeholder="Buscar..."
              control={control}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e) => e?.key === 'Enter' && handleSubmit(loadData)()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="dtemissao"
              control={control}
              type="date"
              label="Data da Venda"
              InputProps={{
                onKeyPress: (e) =>
                  e?.key === 'Enter' && handleSubmit(loadData)(),
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleSubmit(loadData)()}
              loading={loading}
            >
              Buscar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={itens}
              columns={[
                {
                  field: 'documento',
                  headerName: 'Documento',
                  width: 450,
                  sortable: false,
                  renderCell: (props) => <DocumentoCell {...props} />,
                },
                {
                  field: 'produto',
                  headerName: 'Produto',
                  width: 450,
                  sortable: false,
                  renderCell: (props) => <ProdutoCell {...props} />,
                },
                {
                  field: 'saldo',
                  headerName: 'Saldo',
                  type: 'number',
                  width: 150,
                  sortable: false,
                  renderCell: (props) => <SaldoCell {...props} />,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  width: 120,
                  sortable: false,
                  editable: true,
                  renderCell: (props) => <QuantidadeCell {...props} />,
                },
                {
                  field: 'preco',
                  headerName: 'Preço Unitário',
                  type: 'number',
                  width: 165,
                  sortable: false,
                  editable: true,
                  renderCell: (props) => <PrecoCell {...props} />,
                },
                {
                  field: 'desconto1_pc',
                  headerName: 'Desconto',
                  type: 'number',
                  width: 165,
                  sortable: false,
                  editable: true,
                  renderCell: (props) => <DescontoCell {...props} />,
                },
                {
                  field: 'subtotal',
                  headerName: 'Subtotal',
                  type: 'number',
                  width: 120,
                  sortable: false,
                  valueGetter: ({ row }) =>
                    (
                      (row?.preco || 0) * (row?.quantidade || 0) +
                      (row?.desconto_vlr || 0)
                    )?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
              ]}
              autoHeight
              pagination
              pageSize={10}
              density="compact"
              loading={loading}
              disableColumnMenu
              checkboxSelection
              showCellRightBorder
              showColumnRightBorder
              disableSelectionOnClick
              localeText={localeText}
              keepNonExistentRowsSelected
              getRowHeight={() => 'auto'}
              onCellEditCommit={onCellEditCommit}
              selectionModel={selected?.map((s) => s?.id)}
              onSelectionModelChange={onSelectionModelChange}
              components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          loading={loading}
        >
          Confirmar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default HistoricoModal;
