const styles = {
  image: {
    objectFit: 'contain',
    maxHeight: '300px',
    overflow: 'hidden',
    width: '100%',
  },
};

export default styles;
