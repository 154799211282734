import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { toastWarning } from 'utils/toast';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';

const Locais = ({
  user,
  onSubmit,
  onDelete,
  gridProps = {},
  loading = false,
  equipamento = {},
  disableActions = false,
}) => {
  const { openModal, closeModal } = useModal();
  const rotina = 'DocumentoEquipamentoLocal';
  const locais = equipamento?.DocumentoEquipamentoLocals || [];

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      users_id: user?.id,
      documento_equipamento_id: equipamento?.id || null,
      localizacao: item?.localizacao || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);
    const title = Boolean(item) ? 'Editar Local' : 'Adicionar Local';

    const onConfirm = (values) => {
      if (!Boolean(values?.localizacao)) {
        toastWarning('Localização obrigatória');
      } else {
        const Equipamentos = [
          {
            id: equipamento?.id,
            documento_id: equipamento?.documento_id,
            Locais: [values],
          },
        ];
        onSubmit({ Equipamentos });
      }
    };

    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Input name="localizacao" control={control} label="Localização" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Voltar
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Card title="Locais">
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openModal(<Modal />)}
              loading={loading}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={locais}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                hide: disableActions,
                width: 100,
                getActions: ({ row, id }) => [
                  <GridActionsCellItem
                    icon={<Edit />}
                    label="Editar"
                    onClick={() => openModal(<Modal item={row} />)}
                  />,
                  <GridActionsCellItem
                    icon={<Delete />}
                    label="Excluir"
                    onClick={() => onDelete({ rotina, id })}
                  />,
                ],
              },
              {
                field: 'localizacao',
                headerName: 'Localização',
                flex: 1,
                sortable: false,
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Locais;
