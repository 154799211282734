import { createContext, useState } from 'react';
import api from 'services/api';

export const ModelosContext = createContext();

export const ModelosProvider = ({ children }) => {
  const [postLoading, setPostLoading] = useState(false);

  const postModelo = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Grid/Manutencao', {
        data,
        rotina: 'Modelo',
      });
      if (cb) {
        cb(res?.data);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ModelosContext.Provider value={{ postModelo, postLoading }}>
      {children}
    </ModelosContext.Provider>
  );
};
