import React, { useContext, useState } from 'react';
import { Edit, Add, Upload, Close, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { dropTipoServico } from 'utils/drops';
import { toastWarning } from 'utils/toast';
import { useDialog } from 'components/Modals';
import EntidadeModal from 'components/Modals/EntidadeModal';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import moment from 'moment';

const Serviço = () => {
  const titulo = 'Ordem de Serviço';
  const defaultValues = {
    cadastro_id: null,
    tipo: 'CORRETIVA',
    garantia: '',
    assunto: '',
    mail_contato: '',
    defeito: '',
    solicitacao: '',
  };
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const { openDialog } = useDialog();
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });
  const { postLoading, postDocumento, postDocumentoMidia } =
    useContext(DocumentosContext);
  const { drops } = useContext(DropsContext);
  const { user } = useContext(AuthContext);

  const onSubmit = (values) => {
    if (!Boolean(values?.cadastro_id)) {
      return toastWarning('Entidade obrigatória');
    } else {
      const data = {
        Documento: {
          especie_id: 16,
          natureza_operacao_id: 11,
          filial_id: user?.filial_id,
          cadastro_id: values?.cadastro_id,
          dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
          users_id: user?.id,
        },
        Servico: {
          garantia: values?.garantia,
          assunto: values?.assunto,
          mail_contato: values?.mail_contato,
          defeito: values?.defeito,
          solicitacao: values?.solicitacao,
          tipo: values?.tipo,
        },
        Itens: [
          {
            sequencia: 1,
            descricao: values?.solicitacao,
            quantidade: 1,
            Processos: [
              {
                operacao: 10,
                descricao: values?.solicitacao,
                apontamento: 'SIM',
              },
            ],
          },
        ],
        Registros: [
          {
            users_id: user?.id,
            datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
            status_id: 16,
          },
        ],
      };
      if (Boolean(file)) {
        const formData = new FormData();
        formData.append('documento', JSON.stringify(data));
        formData.append('midia', file);
        postDocumentoMidia({ data: formData, cb: () => navigate(-1) });
      } else {
        postDocumento({ data, cb: () => navigate(-1) });
      }
    }
  };

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box flex={1}>
              <VirtualDrop
                name="cadastro_id"
                control={control}
                label="Entidade"
                options={drops?.Entidade}
              />
            </Box>
            <IconButton
              color="primary"
              disabled={!Boolean(watch('cadastro_id'))}
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
            >
              <Visibility />
            </IconButton>
            <IconButton
              color="primary"
              disabled={!Boolean(watch('cadastro_id'))}
              onClick={() =>
                openDialog(
                  <EntidadeModal
                    entidade_id={watch('cadastro_id')}
                    callback={(id) => setValue('cadastro_id', id)}
                  />,
                  'Editar Entidade'
                )
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() =>
                openDialog(
                  <EntidadeModal
                    callback={(id) => setValue('cadastro_id', id)}
                  />,
                  'Cadastrar Entidade'
                )
              }
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="tipo"
              control={control}
              label="Tipo do Serviço"
              options={dropTipoServico}
            />
          </Grid>
          <Grid item xs={12}>
            <Input name="solicitacao" control={control} label="Solicitação" />
          </Grid>
          <Grid item xs={12}>
            <Input name="defeito" control={control} label="Relato do Defeito" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="mail_contato"
              control={control}
              label="Email de Contato"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="assunto" control={control} label="Assunto" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="garantia"
              control={control}
              label="Garantia (Dias)"
              type="number"
            />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Upload />}
              onClick={() => document.getElementById('upload-midia')?.click()}
            >
              <input
                id="upload-midia"
                hidden
                accept="image/*"
                type="file"
                onChange={({ target }) => {
                  setFile(target.files[0] || null);
                  target.value = '';
                }}
              />
              Upload
            </Button>
            {Boolean(file) && (
              <Typography variant="body2" sx={{ mx: 1, color: 'primary.main' }}>
                {file?.name}
              </Typography>
            )}
            {Boolean(file) && (
              <IconButton onClick={() => setFile(null)} size="small">
                <Close />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Serviço;
