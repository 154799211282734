import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  descricao: '',
  referencia: '',
  marca: [],
  modelo: [],
  patrimonio: '',
  alimentacao: [],
};

export const EquipamentosContext = createContext();

export const EquipamentosProvider = ({ children }) => {
  const [equipamentos, setEquipamentos] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getEquipamentos = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: equipamentos?.filter,
        page: equipamentos?.page,
        size: equipamentos?.size,
        order: equipamentos?.order,
        ...payload,
      });
      setEquipamentos((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postEquipamento = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Grid/Manutencao', {
        data,
        rotina: 'Equipamento',
      });
      if (cb) {
        cb(res?.data);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <EquipamentosContext.Provider
      value={{
        equipamentos,
        getLoading,
        getEquipamentos,
        postEquipamento,
        postLoading,
      }}
    >
      {children}
    </EquipamentosContext.Provider>
  );
};
