import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { Delete, Edit, ReportProblem } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { dropBoolean } from 'utils/drops';
import { findOnArray } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';

const Processos = ({
  onSubmit,
  onDelete,
  item = {},
  drops = [],
  gridProps = {},
  loading = false,
  onSubmitProcesso,
  disableActions = false,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoItemProcesso';
  const processos = item?.DocumentoItemProcessos || [];

  const Modal = ({ processo }) => {
    const defaultValues = {
      id: processo?.id,
      documento_item_id: item?.id,
      operacao: processo?.operacao || '',
      descricao: processo?.descricao || '',
      apontamento: processo?.apontamento || null,
      ccusto_id: processo?.ccusto_id || null,
      tpreparacao: processo?.tpreparacao || '',
      texecucao: processo?.texecucao || '',
      analisa_id: processo?.analisa_id || null,
      aprova_id: processo?.aprova_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    const onConfirm = (values) => {
      const data = {
        Itens: [
          {
            id: item?.id,
            documento_id: item?.documento_id,
            Processos: [values],
          },
        ],
      };
      onSubmit(data);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="operacao" control={control} label="Operação" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="apontamento"
            control={control}
            label="Apontamento"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Dropdown
            name="ccusto_id"
            control={control}
            label="Centro de Custo"
            options={drops?.Ccusto}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="tpreparacao"
            control={control}
            label="Tempo de Preparação"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="texecucao"
            control={control}
            label="Tempo de Execução"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="analisa_id"
            control={control}
            label="Responsável pela Análise"
            options={drops?.Users}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="aprova_id"
            control={control}
            label="Responsável pela Aprovação"
            options={drops?.Users}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ApontamentoCell = ({ value = {} }) => {
    if (value === 'SIM') {
      return (
        <Box flex={1} textAlign="center">
          <Chip
            size="small"
            color="error"
            label="Obrigatório"
            icon={<ReportProblem />}
          />
        </Box>
      );
    }
    return null;
  };

  const TemposCell = ({ row = {} }) => {
    const lines = [
      {
        label: 'Preparação',
        value: (row?.tpreparacao || 0) + ' min',
      },
      {
        label: 'Execução',
        value: (row?.texecucao || 0) + ' min',
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(
          ({ label, value, show = true }, i) =>
            Boolean(value) &&
            show && (
              <Box key={i?.toString()} display="flex">
                <Typography variant="caption" flex={1} fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography variant="caption" flex={1} textAlign="end">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const ResponsaveisCell = ({ row = {} }) => {
    const lines = [
      {
        label: 'Análise',
        value: findOnArray(row?.analisa_id, drops?.Users, 'label'),
      },
      {
        label: 'Aprovação',
        value: findOnArray(row?.aprova_id, drops?.Users, 'label'),
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(
          ({ label, value, show = true }, i) =>
            Boolean(value) &&
            show && (
              <Box key={i?.toString()} display="flex">
                <Typography variant="caption" flex={1} fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography variant="caption" flex={3}>
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const options = [
    {
      name: 'Adicionar Processo',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Processo'),
    },
    {
      name: 'Recarregar Processo',
      icon: 'refresh',
      action: () => onSubmitProcesso({ id: item?.id }),
    },
  ];

  return (
    <Card title="Processos">
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined', loading }}
              options={options}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={processos}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                hide: disableActions,
                width: 100,
                getActions: ({ row, id }) => [
                  <GridActionsCellItem
                    icon={<Edit />}
                    label="Editar"
                    onClick={() =>
                      openDialog(<Modal processo={row} />, 'Editar Processo')
                    }
                  />,
                  <GridActionsCellItem
                    icon={<Delete />}
                    label="Excluir"
                    onClick={() => onDelete({ rotina, id })}
                  />,
                ],
              },
              {
                field: 'apontamento',
                headerName: 'Apontamento',
                headerAlign: 'center',
                width: 150,
                sortable: false,
                renderCell: (props) => <ApontamentoCell {...props} />,
              },
              {
                field: 'operacao',
                headerName: 'Operação',
                width: 100,
                sortable: false,
              },
              {
                field: 'descricao',
                headerName: 'Processo',
                width: 500,
                sortable: false,
              },
              {
                field: 'ccusto_id',
                headerName: 'Centro de Custo',
                width: 300,
                sortable: false,
                valueFormatter: ({ value }) =>
                  findOnArray(value, drops?.Ccusto, 'label'),
              },
              {
                field: 'tempos',
                headerName: 'Tempos',
                width: 150,
                sortable: false,
                renderCell: (props) => <TemposCell {...props} />,
              },
              {
                field: 'responsaveis',
                headerName: 'Responsáveis',
                width: 500,
                sortable: false,
                renderCell: (props) => <ResponsaveisCell {...props} />,
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Processos;
