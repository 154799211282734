import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { dropNTributado } from 'utils/drops';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';

const Tributos = ({
  onSubmit,
  onDelete,
  item = {},
  drops = [],
  gridProps = {},
  onSubmitImposto,
  loading = false,
  disableActions = false,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoItemImposto';
  const impostos = item?.DocumentoItemImpostos || [];

  const Modal = ({ imposto }) => {
    const defaultValues = {
      id: imposto?.id,
      documento_item_id: item?.id,
      tributo_id: imposto?.tributo_id || null,
      basecalculo: imposto ? imposto?.basecalculo || '' : item?.preco || '',
      percentual: imposto ? imposto?.percentual || '' : 100,
      valor: imposto?.valor || '',
      situacao_tributaria_id: imposto?.situacao_tributaria_id || null,
      origem_tributaria_id: imposto?.origem_tributaria_id || null,
      ntributado: imposto?.ntributado || null,
      desoneracao_mot: imposto?.desoneracao_mot || '',
      desoneracao_vlr: imposto?.desoneracao_vlr || '',
      reducao_pc: imposto?.reducao_pc || '',
      reducao_vl: imposto?.reducao_vl || '',
    };
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues,
    });
    const { postLoading } = useContext(DocumentosContext);

    useEffect(() => {
      const basecalculo = watch('basecalculo') || 0;
      const percentual = watch('percentual') || 0;
      if (Boolean(basecalculo) && Boolean(percentual)) {
        const valor = (basecalculo * percentual) / 100;
        setValue('valor', Number(valor?.toFixed(2)));
      }
    }, [watch('basecalculo'), watch('percentual')]);

    const onConfirm = (values) => {
      const Itens = [
        { id: item?.id, documento_id: item?.documento_id, Impostos: [values] },
      ];

      onSubmit({ Itens });
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="tributo_id"
            control={control}
            label="Tributo"
            options={drops?.Tributo}
            onValueChange={() => setValue('situacao_tributaria_id', null)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="basecalculo"
            control={control}
            label="Base de Cálculo"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="percentual" control={control} label="Alíquota" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="situacao_tributaria_id"
            control={control}
            label="Situação Tributária"
            options={drops?.SituacaoTributaria?.filter(
              (f) => f?.tributo_id === watch('tributo_id')
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="origem_tributaria_id"
            control={control}
            label="Origem Tributária"
            options={drops?.OrigemTributaria}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="ntributado"
            control={control}
            label="Não Tributado"
            options={dropNTributado}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="desoneracao_mot"
            control={control}
            label="Motivo da Desoneração"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="desoneracao_vlr"
            control={control}
            label="Valor da Desoneração"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="reducao_pc" control={control} label="% da Redução" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="reducao_vl"
            control={control}
            label="Valor da Redução"
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const options = [
    {
      name: 'Adicionar Tributo',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Tributo'),
    },
    {
      name: 'Recarregar Tributos',
      icon: 'refresh',
      action: () => onSubmitImposto([item?.id]),
    },
  ];

  return (
    <Card title="Tributos">
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined', loading }}
              options={options}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={impostos}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                hide: disableActions,
                width: 100,
                getActions: ({ row, id }) => [
                  <GridActionsCellItem
                    icon={<Edit />}
                    label="Editar"
                    onClick={() =>
                      openDialog(<Modal imposto={row} />, 'Editar Tributo')
                    }
                  />,
                  <GridActionsCellItem
                    icon={<Delete />}
                    label="Excluir"
                    onClick={() => onDelete({ rotina, id })}
                  />,
                ],
              },
              {
                field: 'tributo',
                headerName: 'Tributo',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) => row?.Tributo?.descricao,
              },
              {
                field: 'basecalculo',
                headerName: 'Base de Cálculo',
                type: 'number',
                width: 150,
                sortable: false,
                valueFormatter: ({ value }) =>
                  (value || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'percentual',
                headerName: 'Alíquota',
                type: 'number',
                width: 150,
                sortable: false,
                valueFormatter: ({ value }) => (value || 0) + '%',
              },
              {
                field: 'valor',
                headerName: 'Valor',
                type: 'number',
                width: 150,
                sortable: false,
                valueFormatter: ({ value }) =>
                  (value || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'situacao',
                headerName: 'Situação Tributária',
                flex: 1,
                sortable: false,
                valueGetter: ({ row }) => row?.SituacaoTributarium?.descricao,
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Tributos;
