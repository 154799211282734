import { createContext, useState } from 'react';
import api from 'services/api';

export const EntidadesContext = createContext();

export const EntidadesProvider = ({ children }) => {
  const [entidade, setEntidade] = useState(null);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getEntidade = async (id) => {
    try {
      setGetLoading(true);
      setEntidade(null);
      const { data } = await api.get(`/Cadastros/Entidade/${id}`, {
        params: {
          Documentos: true,
          Contatos: true,
          Enderecos: true,
          Perfis: true,
        },
      });
      setEntidade(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postEntidade = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Cadastros/Entidade', data);
      if (cb) {
        cb(res?.data);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <EntidadesContext.Provider
      value={{
        postEntidade,
        postLoading,
        entidade,
        getLoading,
        getEntidade,
        setEntidade,
      }}
    >
      {children}
    </EntidadesContext.Provider>
  );
};
