import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Balance, WorkHistory } from '@mui/icons-material';
import { Box, ButtonGroup, Grid, Typography } from '@mui/material';
import { checkInfosProduto, findOnArray } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { useModal } from 'components/Modals';
import HistoricoModal from 'components/Modals/HistoricoModal';
import SaldoModal from 'components/Modals/SaldoModal';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import Blank from 'assets/blank.png';
import styles from './styles';

const ItemModal = ({ item = {}, documento = {}, onSubmit }) => {
  const defaultValues = {
    id: item?.id,
    documento_id: documento?.id,
    produto_id: item?.produto_id || null,
    descricao: item?.descricao || '',
    referencia: item?.referencia || '',
    quantidade: item?.quantidade || '',
    unidade_medida_id: item?.unidade_medida_id || null,
    przent: item?.przent || null,
    preco: item?.preco || '',
    desconto1_pc: item?.desconto1_pc || '',
    desconto_vlr: item?.desconto_vlr || '',
    planoconta_id: item?.planoconta_id || null,
    ccusto_id: item?.ccusto_id || null,
    aplicacao_id: item?.aplicacao_id || null,
    frota_id: item?.frota_id || null,
    observacao: item?.observacao || '',
    infadc: item?.infadc || '',
    adicionais: null,
  };
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { postLoading } = useContext(DocumentosContext);
  const { control, watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues,
  });
  const [getLoading, setGetLoading] = useState(false);
  const getSaldo = () => {
    const um = findOnArray(
      watch('unidade_medida_id'),
      drops?.UnidadeMedida,
      'label'
    );
    let str = '';
    if (Boolean(watch('adicionais'))) {
      str += `${watch('adicionais.saldo') || 0}`;
      if (Boolean(um)) {
        str += ` ${um || ''}`;
      }
    }
    return str;
  };
  const vendedor_id = documento?.DocumentoSaida?.vendedor_id;
  const renderBtn =
    !Boolean(item) &&
    ['COMPRA', 'VENDA', 'ORCAMENTO']?.includes(documento?.Especie?.natureza);
  const subtotal =
    (watch('preco') || 0) * (watch('quantidade') || 0) +
    (watch('desconto_vlr') || 0);
  const lines = [
    { label: 'Referência', value: watch('referencia') },
    { label: 'Saldo', value: getSaldo() },
    { label: 'Localizador', value: watch('adicionais.localizador') },
  ];

  useEffect(() => {
    const { desconto1_pc, preco, quantidade } = getValues();
    let desconto_vlr = (desconto1_pc || 0) * (preco || 0) * (quantidade || 0);
    if (desconto_vlr !== 0) {
      desconto_vlr = desconto_vlr * -0.01;
    }
    setValue('desconto_vlr', desconto_vlr);
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  const getAdditionals = async (v) => {
    const produto_id = v?.value || null;
    if (!Boolean(produto_id)) {
      setValue('adicionais', null);
    }
    if (Boolean(produto_id) && !Boolean(item?.produto_id)) {
      setGetLoading(true);
      const params = {
        produto_id,
        vendedor_id,
        local_id: documento?.celocal_id,
        filial_id: documento?.filial_id,
        adastro_id: documento?.cadastro_id,
        condicao_pagamento_id: documento?.DocumentoSaida?.condicao_pagamento_id,
      };
      const produto = await checkInfosProduto(params);
      const isEntrada = documento?.Especie?.grupo === 'ENTRADA';
      const preco = isEntrada ? produto?.preco_compra : produto?.preco;
      setValue('descricao', produto?.descricao || '');
      setValue('referencia', produto?.referencia || '');
      setValue('quantidade', produto?.quantidade || 1);
      setValue('unidade_medida_id', produto?.unidade_medida_id || null);
      setValue('preco', preco || '');
      setValue('desconto1_pc', produto?.desconto1_pc || '');
      setValue('adicionais', {
        saldo: produto?.saldo || 0,
        imagem: produto?.imagem || null,
        localizador: produto?.localizador || null,
      });
      setGetLoading(false);
    }
  };

  const GridForm = () => (
    <Grid item xs={12} sm={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VirtualDrop
            name="produto_id"
            control={control}
            label="Produto"
            options={drops?.Produto}
            onValueChange={getAdditionals}
            refresh="Produto"
          />
        </Grid>
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="quantidade" control={control} label="Quantidade" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="unidade_medida_id"
            control={control}
            label="Unidade de Medida"
            options={drops?.UnidadeMedida}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="przent"
            control={control}
            label="Prazo de Entrega"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="preco" control={control} label="Preço Unitário" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="desconto1_pc" control={control} label="% Desconto" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="planoconta_id"
            control={control}
            label="Plano de Conta"
            options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="ccusto_id"
            control={control}
            label="Centro de Custo"
            options={drops?.Ccusto}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="aplicacao_id"
            control={control}
            label="Aplicação"
            options={drops?.Aplicacao}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="frota_id"
            control={control}
            label="Frota"
            options={drops?.Frota}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="observacao"
            control={control}
            label="Observação"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="infadc"
            control={control}
            label="Informações Adicionais"
            multiline
            rows={5}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const GridInfo = () => (
    <Grid item xs={12} sm={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            component="img"
            sx={styles?.image}
            src={watch('adicionais.imagem') || Blank}
          />
        </Grid>
        {lines?.map(({ label, value }, i) => (
          <Grid key={i?.toString()} item xs={12} display="flex">
            <Typography flex={1} fontWeight="bold">
              {label}:
            </Typography>
            <Typography flex={4}>{value || '-'}</Typography>
          </Grid>
        ))}
        <Grid item xs={12} textAlign="center">
          <Typography variant="h6" color="primary">
            Total:{' '}
            {subtotal?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const onChooseProd = (values) => {
    if (Boolean(values)) {
      setValue('infadc', values?.infadc || '');
      setValue('przent', values?.przent || null);
      setValue('quantidade', values?.quantidade || 1);
      setValue('observacao', values?.observacao || '');
      setValue('frota_id', values?.frota_id || null);
      setValue('ccusto_id', values?.ccusto_id || null);
      setValue('desconto1_pc', values?.desconto1_pc || '');
      setValue('desconto_vlr', values?.desconto_vlr || '');
      setValue('aplicacao_id', values?.aplicacao_id || null);
      setValue('planoconta_id', values?.planoconta_id || null);
      setValue('preco', values?.preco || '');
      setValue('produto_id', values?.produto_id || values?.id || null);
      setValue('descricao', values?.descricao || '');
      setValue('referencia', values?.referencia || '');
      setValue('unidade_medida_id', values?.unidade_medida_id || null);
      setValue('adicionais', {
        saldo: values?.saldo || 0,
        imagem: values?.url || null,
        localizador: values?.localizador || null,
      });
    }
    closeModal();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign="center" display={renderBtn}>
        <ButtonGroup variant="outlined" color="primary">
          <Button
            startIcon={<WorkHistory />}
            disabled={!Boolean(documento?.cadastro_id)}
            onClick={() =>
              openModal(
                <HistoricoModal
                  single
                  onSubmit={onChooseProd}
                  cadastro_id={documento?.cadastro_id}
                />,
                90
              )
            }
          >
            Histórico da Entidade
          </Button>
          <Button
            startIcon={<WorkHistory />}
            disabled={!Boolean(watch('produto_id'))}
            onClick={() =>
              openModal(
                <HistoricoModal
                  single
                  onSubmit={onChooseProd}
                  produto_id={watch('produto_id')}
                />,
                90
              )
            }
          >
            Histórico do Produto
          </Button>
          <Button
            startIcon={<Balance />}
            onClick={() =>
              openModal(
                <SaldoModal
                  single
                  onSubmit={onChooseProd}
                  vendedor_id={vendedor_id}
                />,
                90
              )
            }
          >
            Saldo de Produtos
          </Button>
        </ButtonGroup>
      </Grid>
      {GridForm()}
      {GridInfo()}
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={getLoading || postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          SALVAR
        </Button>
      </Grid>
    </Grid>
  );
};
export default ItemModal;
