import { createContext, useState } from 'react';
import api from 'services/api';

export const MarcasContext = createContext();

export const MarcasProvider = ({ children }) => {
  const [postLoading, setPostLoading] = useState(false);

  const postMarca = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Grid/Manutencao', {
        data,
        rotina: 'Cemarca',
      });
      if (cb) {
        cb(res?.data);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <MarcasContext.Provider value={{ postMarca, postLoading }}>
      {children}
    </MarcasContext.Provider>
  );
};
