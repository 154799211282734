import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toastWarning } from 'utils/toast';
import { encryptURL } from 'utils/functions';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { ServicosContext } from 'contexts/ServicosContext';
import { VendaContext } from 'contexts/VendaContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog, useModal } from 'components/Modals';
import JustificativaModal from 'components/Modals/JustificativaModal';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import ConclusaoModal from './Modals/ConclusaoModal';
import Filter from './filter';
import mock from './mock';

const Servicos = () => {
  const navigate = useNavigate();
  const rotina = 'Servicos';
  const titulo = 'Ordens de Serviço';
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { contas } = useContext(VendaContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const { servicos, getLoading, getServicos, exportOrdens } =
    useContext(ServicosContext);
  const { control, getValues, reset } = useForm({
    defaultValues: servicos?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getServicos({ ...props, rotina, filter: getValues() });
  };

  const changeStatus = ({ documento_id, status_id, motivo }) => {
    const data = { Registros: [{ documento_id, status_id, motivo }] };
    postDocumento({
      data,
      cb: () => {
        loadGrid();
        closeDialog();
        closeModal();
      },
    });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: servicos?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: servicos.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={servicos?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Adicionar Ordem de Serviço',
      icon: 'add',
      action: () => navigate('/app/Servicos/Gerar'),
      show: !Boolean(user?.permissoes?.block_gerar_servico),
    },
    {
      name: 'Ordens',
      icon: 'print',
      action: (selected) => {
        if (Boolean(selected?.length)) {
          const data = selected?.map((s) => s?.id);
          exportOrdens({ data, cb: loadGrid });
        } else {
          return toastWarning('Selecione ao menos um item');
        }
      },
    },
  ];
  const rowOptions = [
    {
      name: 'Em execução',
      icon: 'schedule',
      color: 'primary.main',
      show: ({ row }) => Boolean(row?.executantes),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
    {
      name: 'Agendamento',
      icon: 'calendar_month',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_agendamento_servico) &&
        [16]?.includes(row?.status_id),
      action: ({ row }) =>
        changeStatus({ documento_id: row?.id, status_id: 22 }),
    },
    {
      name: 'Apontamento de Horas',
      icon: 'schedule',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_apontamento_servico) &&
        [45]?.includes(row?.status_id),
      action: ({ row }) =>
        navigate(`/app/Servicos/Apontamento/${encryptURL(row)}`),
    },
    {
      name: 'Direcionamento Técnico',
      icon: 'build',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_direcionamento_tecnico) &&
        [16, 21, 22, 30, 45]?.includes(row?.status_id),
      action: ({ row }) =>
        navigate(`/app/Servicos/Direcionamento/${encryptURL(row)}`),
    },
    {
      name: 'Concluir Serviço',
      icon: 'check',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_conclusao_servico) &&
        row?.status === 'PENDENTE',
      action: ({ row }) =>
        openModal(
          <ConclusaoModal
            item={row}
            onSubmit={({ status_id }) =>
              changeStatus({ documento_id: row?.id, status_id })
            }
          />
        ),
    },
    {
      name: 'Entregar Equipamento',
      icon: 'check',
      menu: true,
      show: ({ row }) =>
        Boolean(row?.equipamento_id) &&
        !Boolean(user?.permissoes?.block_entrega_equipamento) &&
        [26]?.includes(row?.status_id),
      action: ({ row }) =>
        changeStatus({
          documento_id: row?.id,
          status_id: 6,
          motivo: 'Equipamento Entregue',
        }),
    },
    {
      name: 'Cancelar Serviço',
      icon: 'cancel',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_cancelamento_servico) &&
        row?.status === 'PENDENTE',
      action: ({ row }) =>
        openDialog(
          <JustificativaModal
            onSubmit={({ motivo }) =>
              changeStatus({ documento_id: row?.id, status_id: 2, motivo })
            }
          />,
          'Cancelar Serviço'
        ),
    },
    {
      name: 'Resumo da Ordem de Serviço',
      icon: 'description',
      menu: true,
      show: () => !Boolean(user?.permissoes?.block_resumo_servico),
      action: ({ row }) => navigate(`/app/Servicos/Resumo/${encryptURL(row)}`),
    },
    {
      name: 'Faturar',
      icon: 'attach_money',
      menu: true,
      show: ({ row }) =>
        !Boolean(user?.permissoes?.block_faturamento_servico) &&
        [20]?.includes(row?.status_id),
      action: ({ id }) => {
        const hash = encryptURL({ id });
        if (Boolean(contas?.cxconta_id) && !Boolean(contas?.seqcaixa)) {
          return toastWarning('Você deve abrir o caixa para continuar');
        }
        navigate(`/app/Servicos/Faturamento/${hash}`);
      },
    },
    {
      name: 'Imprimir Ordem de Serviço',
      icon: 'print',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: { codigo: 'OS00000001', documento_id: row?.id },
          cb: loadGrid,
        }),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={servicos?.colunas} />
      <Card>
        <Grid
          grid={servicos}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading || postLoading}
          loadGrid={loadGrid}
          getRowHeight={() => 'auto'}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={servicos} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Servicos;
