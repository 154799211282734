import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { toastWarning } from 'utils/toast';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const Defeitos = ({
  user,
  onSubmit,
  onDelete,
  drops = [],
  gridProps = {},
  loading = false,
  equipamento = {},
  disableActions = false,
}) => {
  const { openModal, closeModal } = useModal();
  const rotina = 'DocumentoEquipamentoDefeito';
  const defeitos = equipamento?.DocumentoEquipamentoDefeitos || [];

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      users_id: user?.id,
      documento_equipamento_id: equipamento?.id || null,
      defeito_id: item?.defeito_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);
    const title = Boolean(item) ? 'Editar Defeito' : 'Adicionar Defeito';

    const onConfirm = (values) => {
      if (!Boolean(values?.defeito_id)) {
        toastWarning('Defeito obrigatório');
      } else {
        const Equipamentos = [
          {
            id: equipamento?.id,
            documento_id: equipamento?.documento_id,
            Defeitos: [values],
          },
        ];
        onSubmit({ Equipamentos });
      }
    };

    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Dropdown
                name="defeito_id"
                control={control}
                label="Defeito"
                options={drops?.Defeito}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Voltar
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Card title="Defeitos">
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openModal(<Modal />)}
              loading={loading}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={defeitos}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                hide: disableActions,
                width: 100,
                getActions: ({ row, id }) => [
                  <GridActionsCellItem
                    icon={<Edit />}
                    label="Editar"
                    onClick={() => openModal(<Modal item={row} />)}
                  />,
                  <GridActionsCellItem
                    icon={<Delete />}
                    label="Excluir"
                    onClick={() => onDelete({ rotina, id })}
                  />,
                ],
              },
              {
                field: 'defeito',
                headerName: 'Defeito',
                flex: 1,
                sortable: false,
                valueGetter: ({ row }) => row?.Defeito?.descricao,
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Defeitos;
