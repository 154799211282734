import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { summarizer } from 'utils/functions';
import columns from './columns';
import localeText from 'utils/localeText';
import { LinearProgress, Pagination } from '@mui/material';

const ItensGrid = ({
  itens = [],
  actions = [],
  documento = {},
  ...gridProps
}) => {
  const rows = itens?.map((item) => {
    const preco = item?.preco || 0;
    const quantidade = item?.quantidade || 0;
    const desconto_vlr = item?.desconto_vlr || 0;
    const frete = item?.frete || 0;
    const seguro = item?.seguro || 0;
    const despac = item?.despac || 0;
    const eventuais = item?.eventuais || 0;
    const outros = item?.outros || 0;
    const impostos = item?.DocumentoItemImpostos.map((dii) => {
      const operacao = dii?.SituacaoTributarium?.operacaotd;
      const color = {
        SOMA: 'success.main',
        SUBTRAI: 'error',
        NENHUMA: 'grey',
      };
      const valor = { SOMA: 1, SUBTRAI: -1, NENHUMA: 1 };
      const subtotal = { SOMA: 1, SUBTRAI: -1, NENHUMA: 0 };
      return {
        color: color[operacao],
        descricao: dii?.Tributo?.descricao,
        valor: dii?.valor * valor[operacao],
        subtotal: dii?.valor * subtotal[operacao],
      };
    });
    const imposto = summarizer(impostos, 'subtotal') || 0;
    return {
      ...item,
      quantidade,
      preco,
      desconto_vlr,
      frete,
      seguro,
      despac,
      eventuais,
      outros,
      impostos,
      imposto,
      subtotal:
        preco * quantidade +
        desconto_vlr +
        frete +
        seguro +
        despac +
        eventuais +
        outros +
        imposto,
    };
  });
  return (
    <DataGrid
      {...gridProps}
      autoHeight
      pagination
      pageSize={25}
      rows={rows}
      columns={columns({ documento, actions })}
      disableColumnMenu
      showCellRightBorder
      showColumnRightBorder
      disableSelectionOnClick
      density="compact"
      localeText={localeText}
      getRowHeight={() => 'auto'}
      components={{
        LoadingOverlay: LinearProgress,
        Pagination: () => {
          const gridRef = useGridApiContext();
          const count = useGridSelector(gridRef, gridPageCountSelector);
          return (
            <Pagination
              color="primary"
              count={count}
              onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
            />
          );
        },
      }}
    />
  );
};

export default ItensGrid;
