import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
  Checkbox,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { toastError } from 'utils/toast';
import { useModal } from 'components/Modals';
import Input from 'components/Input';
import Button from 'components/Button';
import api from 'services/api';

const PesquisaEquipamentoModal = ({ onSubmit }) => {
  const [equipamentos, setEquipamentos] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm({ defaultValues: { sn: '' } });
  const { closeModal } = useModal();

  const onSearch = async (values) => {
    try {
      setLoading(true);
      setSelected(null);
      setEquipamentos([]);
      const { data } = await api.get(`/Cadastros/Equipamento/${values?.sn}`);
      if (!Boolean(data?.length)) {
        toastError({ message: 'Nenhum equipamento encontrado' });
      }
      setEquipamentos(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogTitle>Pesquisar Equipamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Input name="sn" control={control} label="Serial Number" />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              onClick={handleSubmit(onSearch)}
              loading={loading}
            >
              Pesquisar
            </Button>
          </Grid>
          {!!equipamentos?.length && (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Equipamento</TableCell>
                      <TableCell>Marca</TableCell>
                      <TableCell>Modelo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {equipamentos.map((e) => (
                      <TableRow key={e?.equipamento_id?.toString()}>
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={
                              selected?.equipamento_id === e?.equipamento_id
                            }
                            onChange={() => {
                              if (
                                selected?.equipamento_id === e?.equipamento_id
                              ) {
                                setSelected(null);
                              } else {
                                setSelected(e);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>{e.equipamento}</TableCell>
                        <TableCell>{e.marca}</TableCell>
                        <TableCell>{e.modelo}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmit(selected)}>Confirmar</Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default PesquisaEquipamentoModal;
