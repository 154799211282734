import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit, Info, Print } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { findOnArray, summarizer } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';
import moment from 'moment';
import styles from './styles';

const Financeiro = ({
  onSubmit,
  onDelete,
  drops = [],
  documento = {},
  gridProps = {},
  getURLRelatorio,
  loading = false,
  disableActions = false,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoFinanceiro';
  const financeiros = documento?.DocumentoFinanceiros || [];
  const forma =
    documento?.Especie?.grupo === 'SAIDA' ||
    (documento?.NaturezaOperacao?.devolucao === 'SIM' &&
      documento?.NaturezaOperacao?.operacao === 'ENTRADA')
      ? ' recebido'
      : documento?.Especie?.grupo === 'ENTRADA' ||
        (documento?.NaturezaOperacao?.devolucao === 'SIM' &&
          documento?.NaturezaOperacao?.operacao === 'SAIDA')
      ? ' pago'
      : '';
  const dropsFormaDePagamento = drops?.FormaDePagamento?.filter((f) => {
    if (documento?.Especie?.grupo === 'ENTRADA') {
      return f?.modulo !== 'VENDAS' && f?.modulo !== 'CHEQUES';
    } else if (documento?.Especie?.grupo === 'SAIDA') {
      return f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES';
    }
    return f;
  });

  const Modal = ({ item }) => {
    const resto =
      (documento?.Totais?.documento || 0) -
      (summarizer(documento?.DocumentoFinanceiros || [], 'valor') || 0);
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      parcela: item?.parcela || financeiros?.length + 1,
      dtvenc: item?.dtvenc || null,
      valor: item?.valor || (resto > 0 && resto) || '',
      forma_pagto_id: item?.forma_pagto_id || null,
      observacao: item?.observacao || '',
      fixo: true,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);
    const onConfirm = (data) => onSubmit({ Financeiros: [data] });

    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputMask
            name="dtvenc"
            control={control}
            label="Data de Vencimento"
            type="date"
          />
        </Grid>
        <Grid item xs={4}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
        <Grid item xs={4}>
          <Dropdown
            name="forma_pagto_id"
            control={control}
            label="Forma de Pagamento"
            options={dropsFormaDePagamento}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="observacao"
            control={control}
            label="Observação"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ParcelaModal = () => {
    const defaultValues = {
      documento_id: documento?.id,
      condicao_pagamento_id: null,
      forma_pagto_id: null,
      posterga: true,
    };
    const { control, handleSubmit, watch } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);
    const dropsCondicaoDePagamento = findOnArray(
      watch('forma_pagto_id'),
      dropsFormaDePagamento,
      'condicoes'
    );
    const onConfirm = (Financeiros) => onSubmit({ Financeiros });

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="forma_pagto_id"
            control={control}
            label="Forma de Pagamento"
            options={dropsFormaDePagamento}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="condicao_pagamento_id"
            control={control}
            label="Condição de Pagamento"
            options={dropsCondicaoDePagamento}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const StatusCell = ({ row }) => {
    const pagamentos = [];
    row?.Financeiros?.map((f) => f?.fh?.map((h) => pagamentos.push(h)));
    const valor_acordado = row?.valor || 0;
    const valor_pago = summarizer(pagamentos, 'valor');
    const percentual =
      valor_acordado > 0 ? (valor_pago * 100) / valor_acordado : 0;
    const progress = Math.round(percentual < 100 ? percentual : 100);

    return (
      <Box sx={styles?.line}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={styles?.progress}
          color="success"
        />
        <Typography variant="body2">{percentual}%</Typography>
      </Box>
    );
  };

  const PropostosCell = ({ row }) => {
    const rows = [
      {
        label: 'Valor',
        value: row?.valor?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
      {
        label: 'Vencimento',
        value: moment(row?.dtvenc).format('DD/MM/YYYY'),
      },
      {
        label: 'Forma de Pagamento',
        value: row?.FormaPagto?.descricao,
      },
    ];

    return (
      <Box sx={styles?.container}>
        {rows?.map(
          (r, i) =>
            Boolean(r?.value) && (
              <Box key={i?.toString()} sx={styles?.line}>
                <Typography sx={styles?.lineLabel} variant="caption">
                  {r?.label}:{' '}
                </Typography>
                <Typography sx={styles?.lineValue} variant="caption">
                  {r?.value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const RealizadosCell = ({ row }) => {
    const pagamentos = [];
    row?.Financeiros?.map((f) => f?.fh?.map((h) => pagamentos.push(h)));

    const Line = (p, i) => {
      const [anchorEl, setAnchorEl] = useState(null);
      const pagamento = p?.FormaPagto?.descricao;
      const date = p?.dtlanc && moment(p?.dtlanc).format('DD/MM/YYYY');
      const valor = (p?.valor || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
      const lancamentos = [
        { label: 'Nº do Movimento', value: p?.financeiro_movimento_id },
        { label: 'Conta', value: p?.conta },
        { label: 'Usuário', value: p?.User?.name },
        {
          label: 'Data / Hora',
          value:
            p?.datahora && moment(p?.datahora).format('DD/MM/YYYY [às] HH:mm'),
        },
      ];
      if (valor <= 0) {
        return null;
      }
      return (
        <Box key={i?.toString()} sx={styles?.line}>
          <Typography variant="body2" sx={{ flex: 1 }}>
            • {valor}
            {pagamento && ` em ${pagamento}`}
            {forma}
            {date && ` em ${date}`}
          </Typography>
          <Tooltip title="Informações do Lançamento" placement="left">
            <IconButton
              size="small"
              color="primary"
              onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
            >
              <Info fontSize="small" />
            </IconButton>
          </Tooltip>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Box sx={styles?.popover}>
              <Typography sx={styles?.lineTitle} variant="body2" align="center">
                Informações do Lançamento
              </Typography>
              {lancamentos?.map(
                (r, i) =>
                  Boolean(r?.value) && (
                    <Box key={i?.toString()} sx={styles?.line}>
                      <Typography sx={styles?.lineLabel} variant="caption">
                        {r?.label}:{' '}
                      </Typography>
                      <Typography sx={styles?.lineValue} variant="caption">
                        {r?.value}
                      </Typography>
                    </Box>
                  )
              )}
            </Box>
          </Popover>
        </Box>
      );
    };

    return <Box sx={styles?.container}>{pagamentos?.map(Line)}</Box>;
  };

  const getActions = ({ row, id }) => {
    const actions = [];
    const pagamentos = [];
    const boletos = [];
    row?.Financeiros?.map((f) => {
      if (Boolean(f?.FinanceiroBoleto)) {
        boletos?.push(f?.id);
      }
      f?.fh?.map((h) => pagamentos.push(h));
    });
    const valor_acordado = row?.valor || 0;
    const valor_pago = summarizer(pagamentos, 'valor');
    const percentual =
      valor_acordado > 0 ? (valor_pago * 100) / valor_acordado : 0;
    const progress = Math.round(percentual < 100 ? percentual : 100);
    if (progress < 100) {
      actions?.push(
        <GridActionsCellItem
          showInMenu
          icon={<Print />}
          label="Duplicata"
          onClick={() =>
            getURLRelatorio({
              data: { codigo: 'DUP0000001', documento_financeiro_id: id },
            })
          }
        />
      );
    }
    if (progress < 100 && Boolean(boletos?.length)) {
      actions?.push(
        <GridActionsCellItem
          showInMenu
          icon={<Print />}
          label="Boleto"
          onClick={() =>
            getURLRelatorio({
              data: {
                codigo: 'BOL0000001',
                financeiros: JSON.stringify(boletos),
              },
            })
          }
        />
      );
    }
    if (!disableActions) {
      actions?.push(
        <GridActionsCellItem
          icon={<Edit />}
          label="Editar"
          onClick={() => openDialog(<Modal item={row} />, 'Editar Parcela')}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Excluir"
          onClick={() => onDelete({ rotina, id })}
        />
      );
    }
    return actions;
  };

  const options = [
    {
      name: 'Adicionar Parcela',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Parcela'),
    },
    {
      name: 'Gerar parcelas',
      icon: 'add',
      action: () => openDialog(<ParcelaModal />, 'Gerar parcelas'),
    },
    {
      name: 'Gerar parcelas com base no histórico',
      icon: 'add',
      action: () => {
        const Financeiros = { documento_id: documento?.id, posterga: true };
        onSubmit({ Financeiros });
      },
    },
    {
      name: 'Aguardar pagamento',
      icon: 'work_history',
      show: documento?.especie_id === 9,
      action: () => {
        const Registros = [{ documento_id: documento?.id, status_id: 34 }];
        onSubmit({ Registros });
      },
    },
  ];

  return (
    <Card title="Financeiro">
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined', loading }}
              options={options}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            rows={financeiros}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Ações',
                width: 150,
                getActions,
              },
              {
                field: 'parcela',
                headerName: 'Parcela',
                type: 'number',
                width: 100,
                sortable: false,
                valueFormatter: ({ value }) => `${value || 0}ª`,
              },
              {
                field: 'propostos',
                headerName: 'Pagamentos Propostos',
                width: 500,
                sortable: false,
                renderCell: (props) => <PropostosCell {...props} />,
              },
              {
                field: 'realizados',
                headerName: 'Pagamentos Realizados',
                width: 500,
                sortable: false,
                renderCell: (props) => <RealizadosCell {...props} />,
              },
              {
                field: 'status',
                headerName: 'Status do Pagamento',
                headerAlign: 'center',
                width: 250,
                sortable: false,
                renderCell: (props) => <StatusCell {...props} />,
              },
            ]}
            {...gridProps}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Financeiro;
