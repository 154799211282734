import { createContext, useState } from 'react';
import api from 'services/api';

export const AgendaContext = createContext();

export const AgendaProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [agendas, setAgendas] = useState([]);

  const getAgendas = async (params) => {
    try {
      setLoading(true);
      const { data } = await api.get('/Cadastros/Tarefa', { params });
      setAgendas(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <AgendaContext.Provider value={{ getAgendas, loading, agendas }}>
      {children}
    </AgendaContext.Provider>
  );
};
