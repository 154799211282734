import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { dropBoolean } from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';

const Item = ({
  onSubmit,
  item = {},
  drops = [],
  loading = false,
  disableActions = false,
}) => {
  const defaultValues = {
    id: item?.id,
    documento_id: item?.documento_id,
    produto_id: item?.produto_id || null,
    descricao: item?.descricao || '',
    cfo_id: item?.cfo_id || null,
    cfiscal_id: item?.cfiscal_id || null,
    sequencia: item?.sequencia || '',
    referencia: item?.referencia || '',
    unidade_medida_id: item?.unidade_medida_id || null,
    quantidade: item?.quantidade || '',
    preco: item?.preco || '',
    peso: item?.peso || '',
    comissao_pc: item?.comissao_pc || '',
    desconto_vlr: item?.desconto_vlr || '',
    desconto1_pc: item?.desconto1_pc || '',
    desconto2_pc: item?.desconto2_pc || '',
    desconto3_pc: item?.desconto3_pc || '',
    desconto4_pc: item?.desconto4_pc || '',
    desconto5_pc: item?.desconto5_pc || '',
    frete: item?.frete || '',
    seguro: item?.seguro || '',
    despac: item?.despac || '',
    eventuais: item?.eventuais || '',
    outros: item?.outros || '',
    cstfin: item?.cstfin || '',
    planoconta_id: item?.planoconta_id || null,
    ccusto_id: item?.ccusto_id || null,
    aplicacao_id: item?.aplicacao_id || null,
    frota_id: item?.frota_id || null,
    pedcomp: item?.pedcomp || '',
    itempedcomp: item?.itempedcomp || '',
    reserva: item?.reserva || null,
    przent: item?.przent || null,
    observacao: item?.observacao || '',
    infadc: item?.infadc || '',
  };
  const { control, watch, setValue, handleSubmit } = useForm({ defaultValues });

  const onChangeDescontoPc = () => {
    let desconto_vlr = 0;
    const total = Number(
      ((watch('preco') || 0) * (watch('quantidade') || 0))?.toFixed(2)
    );
    const desconto1 = Number(((watch('desconto1_pc') || 0) / 100)?.toFixed(2));
    if (Boolean(desconto1)) {
      desconto_vlr += Number(((total - desconto_vlr) * desconto1)?.toFixed(2));
    } else {
      setValue('desconto2_pc', '');
    }
    const desconto2 = Number(((watch('desconto2_pc') || 0) / 100)?.toFixed(2));
    if (Boolean(desconto2)) {
      desconto_vlr += Number(((total - desconto_vlr) * desconto2)?.toFixed(2));
    } else {
      setValue('desconto3_pc', '');
    }
    const desconto3 = Number(((watch('desconto3_pc') || 0) / 100)?.toFixed(2));
    if (Boolean(desconto3)) {
      desconto_vlr += Number(((total - desconto_vlr) * desconto3)?.toFixed(2));
    } else {
      setValue('desconto4_pc', '');
    }
    const desconto4 = Number(((watch('desconto4_pc') || 0) / 100)?.toFixed(2));
    if (Boolean(desconto4)) {
      desconto_vlr += Number(((total - desconto_vlr) * desconto4)?.toFixed(2));
    } else {
      setValue('desconto5_pc', '');
    }
    const desconto5 = Number(((watch('desconto5_pc') || 0) / 100)?.toFixed(2));
    if (Boolean(desconto5)) {
      desconto_vlr += Number(((total - desconto_vlr) * desconto5)?.toFixed(2));
    }
    setValue('desconto_vlr', desconto_vlr ? desconto_vlr * -1 : '');
  };

  const onChangeDescontoVlr = () => {
    const total = Number(
      ((watch('preco') || 0) * (watch('quantidade') || 0))?.toFixed(2)
    );
    const desconto1_pc = Number(
      ((100 * (watch('desconto_vlr') || 0)) / total)?.toFixed(2)
    );
    setValue('desconto1_pc', desconto1_pc ? desconto1_pc * -1 : '');
    setValue('desconto2_pc', '');
    setValue('desconto3_pc', '');
    setValue('desconto4_pc', '');
    setValue('desconto5_pc', '');
  };

  return (
    <Card title="Informações Gerais">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VirtualDrop
            name="produto_id"
            control={control}
            label="Produto"
            options={drops?.Produto}
            refresh="Produto"
          />
        </Grid>
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="cfo_id"
            control={control}
            label="CFOP"
            options={drops?.Cfo}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VirtualDrop
            name="cfiscal_id"
            control={control}
            label="Classificação Fiscal"
            options={drops?.Cfiscal}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="sequencia" control={control} label="Sequência" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="unidade_medida_id"
            control={control}
            label="Unidade de Medida"
            options={drops?.UnidadeMedida}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="quantidade" control={control} label="Quantidade" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="preco" control={control} label="Preço Unitário" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="peso" control={control} label="Peso" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="comissao_pc" control={control} label="% Comissão" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto_vlr"
            control={control}
            label="Valor do Desconto"
            onBlur={onChangeDescontoVlr}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto1_pc"
            control={control}
            label="% do Desconto 1"
            onBlur={onChangeDescontoPc}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto2_pc"
            control={control}
            label="% do Desconto 2"
            onBlur={onChangeDescontoPc}
            disabled={!Boolean(watch('desconto1_pc'))}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto3_pc"
            control={control}
            label="% do Desconto 3"
            onBlur={onChangeDescontoPc}
            disabled={!Boolean(watch('desconto2_pc'))}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto4_pc"
            control={control}
            label="% do Desconto 4"
            onBlur={onChangeDescontoPc}
            disabled={!Boolean(watch('desconto3_pc'))}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto5_pc"
            control={control}
            label="% do Desconto 5"
            onBlur={onChangeDescontoPc}
            disabled={!Boolean(watch('desconto4_pc'))}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="frete" control={control} label="Frete" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="seguro" control={control} label="Seguro" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="despac"
            control={control}
            label="Despesas Acessórias"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="eventuais" control={control} label="Eventuais" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="outros" control={control} label="Outros" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="cstfin" control={control} label="Custo Financeiro" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="planoconta_id"
            control={control}
            label="Plano de Conta"
            options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="ccusto_id"
            control={control}
            label="Centro de Custo"
            options={drops?.Ccusto}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="aplicacao_id"
            control={control}
            label="Aplicação"
            options={drops?.Aplicacao}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="frota_id"
            control={control}
            label="Frota"
            options={drops?.Frota}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input name="pedcomp" control={control} label="Pedido de Compra" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="itempedcomp"
            control={control}
            label="Item do Pedido de Compra"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="reserva"
            control={control}
            label="Reserva"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="przent"
            control={control}
            label="Prazo de Entrega"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="observacao"
            control={control}
            label="Observação"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="infadc"
            control={control}
            label="Informações Adicionais"
            multiline
            rows={5}
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              loading={loading}
              onClick={handleSubmit((data) => onSubmit({ Itens: [data] }))}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Item;
