import React, { useContext, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
  Radio,
  InputLabel,
  Checkbox,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { findOnArray } from 'utils/functions';
import { toastWarning, toastError } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { useModal } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import Button from 'components/Button';
import axios from 'axios';

const PesquisaEnderecoModal = ({ onSubmit }) => {
  const [enderecos, setEnderecos] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const { closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { control, handleSubmit, resetField, watch, setValue, reset } = useForm(
    {
      defaultValues: {
        tipo: 'cep',
        cep: '',
        endereco: '',
        estado: null,
        cidade: null,
      },
    }
  );

  const onSearch = async (values) => {
    setLoading(true);
    setSelected(null);
    setEnderecos([]);
    if (Boolean(values?.cep)) {
      const cep = values?.cep?.replace(/[.-]/g, '');
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (!data?.erro) {
        setEnderecos([data]);
      } else {
        toastError({ message: 'Nenhum endereço encontrado' });
      }
    } else if (
      Boolean(values?.endereco) &&
      Boolean(values?.estado) &&
      Boolean(values?.cidade)
    ) {
      const uf = findOnArray(values?.estado, drops?.UF)?.sigla;
      const cidade = findOnArray(values?.cidade, drops?.Cidade)?.localidade;
      const endereco = values?.endereco;
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${uf}/${cidade}/${endereco}/json/`
      );
      if (!data?.erro) {
        setEnderecos(data);
      } else {
        toastError({ message: 'Nenhum endereço encontrado' });
      }
    } else {
      toastWarning('Preencha todos os campos');
    }
    setLoading(false);
  };

  return (
    <>
      <DialogTitle>Pesquisar Endereço</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} display="flex" alignItems="center">
            <Radio
              name="cep"
              id="cep"
              color="primary"
              checked={watch('tipo') === 'cep'}
              onChange={() => {
                setValue('tipo', 'cep');
                resetField('cep');
                resetField('endereco');
                resetField('estado');
                resetField('cidade');
              }}
            />
            <InputLabel htmlFor="cep">CEP</InputLabel>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center">
            <Radio
              name="endereco"
              id="endereco"
              color="primary"
              checked={watch('tipo') === 'endereco'}
              onChange={() => {
                setValue('tipo', 'endereco');
                resetField('cep');
                resetField('endereco');
                resetField('estado');
                resetField('cidade');
              }}
            />
            <InputLabel htmlFor="endereco">ENDEREÇO</InputLabel>
          </Grid>
          {watch('tipo') === 'cep' && (
            <Grid item xs={12} sm={6}>
              <InputMask
                name="cep"
                control={control}
                label="CEP"
                mask="99999-999"
              />
            </Grid>
          )}
          {watch('tipo') === 'endereco' && (
            <Grid item xs={12} sm={6}>
              <Input name="endereco" control={control} label="Endereco" />
            </Grid>
          )}
          {watch('tipo') === 'endereco' && (
            <Grid item xs={12} sm={3}>
              <Dropdown
                name="estado"
                control={control}
                label="Estado"
                options={drops?.UF}
                onValueChange={() => resetField('cidade')}
              />
            </Grid>
          )}
          {watch('tipo') === 'endereco' && (
            <Grid item xs={12} sm={3}>
              <Dropdown
                name="cidade"
                control={control}
                label="Cidade"
                options={drops?.Cidade?.filter((c) => {
                  const estado = findOnArray(watch('estado'), drops?.UF);
                  return estado?.sigla === c?.sigla;
                })}
              />
            </Grid>
          )}
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              onClick={handleSubmit(onSearch)}
              loading={loading}
            >
              Pesquisar
            </Button>
          </Grid>
          {!!enderecos?.length && (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>CEP</TableCell>
                      <TableCell>Logradouro</TableCell>
                      <TableCell>Bairro</TableCell>
                      <TableCell>Cidade</TableCell>
                      <TableCell>UF</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {enderecos.map((e) => (
                      <TableRow key={e.cep}>
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={selected?.cep === e?.cep}
                            onChange={() => {
                              if (selected?.cep === e?.cep) {
                                setSelected(null);
                              } else {
                                setSelected(e);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>{e.cep}</TableCell>
                        <TableCell>{e.logradouro}</TableCell>
                        <TableCell>{e.bairro}</TableCell>
                        <TableCell>{e.localidade}</TableCell>
                        <TableCell>{e.uf}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmit(selected)}>Confirmar</Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default PesquisaEnderecoModal;
