import React, { useContext } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import App from 'views/App';
import User from 'views/User';
import Agenda from 'views/Agenda';
import Resumo from 'views/Resumo';
import Equipamentos from 'views/Equipamentos';
import Configurações from 'views/Configurações';
import Servicos from 'views/Servicos';
import GerarServiço from 'views/Servicos/Gerar/Serviço';
import GerarEquipamento from 'views/Servicos/Gerar/Equipamento';
import GerarEquipamentos from 'views/Servicos/Gerar/Equipamentos';
import Direcionamento from 'views/Servicos/Direcionamento';
import Faturamento from 'views/Servicos/Faturamento';
import Apontamento from 'views/Servicos/Apontamento';
import Apontamentos from 'views/Servicos/Apontamentos';
import ResumoOS from 'views/Servicos/Resumo';
import Documento from 'views/Documento';
import DocumentoItem from 'views/DocumentoItem';
import DocumentoGarantia from 'views/Documento/Garantia';
import DocumentoEquipamento from 'views/DocumentoEquipamento';
import Orçamentos from 'views/Orçamentos';
import GerarOrçamento from 'views/Orçamentos/Gerar';
import Relatórios from 'views/Relatórios';
import RelatóriosFiltros from 'views/Relatórios/Filtros';
//OUTLETS
import OutletDocumento from './Outlets/Documento';

const RoutesTree = () => {
  const { user } = useContext(AuthContext);
  const CustomGerar = () =>
    user?.atividade_filial === 877 ? <GerarEquipamento /> : <GerarServiço />;
  return (
    <Routes>
      <Route path="" element={<App />} />
      <Route path="Resumo" element={<Resumo />} />
      <Route path="Configuracoes" element={<Configurações />} />
      <Route path="User" element={<User />} />
      <Route path="Equipamentos" element={<Equipamentos />} />
      <Route path="Agenda" element={<Agenda />} />
      <Route path="Relatorios" element={<Outlet />}>
        <Route path="" element={<Relatórios />} />
        <Route path="Filtros" element={<RelatóriosFiltros />} />
      </Route>
      <Route path="Servicos" element={<Outlet />}>
        <Route path="" element={<Servicos />} />
        <Route path="Gerar" element={<CustomGerar />} />
        <Route path="Direcionamento/:hash" element={<Direcionamento />} />
        <Route path="Faturamento/:hash" element={<Faturamento />} />
        <Route path="Resumo/:hash" element={<ResumoOS />} />
        <Route path="Apontamento/:hash" element={<Apontamento />} />
        <Route path="Apontamentos" element={<Apontamentos />} />
      </Route>
      <Route path="Orcamentos" element={<Outlet />}>
        <Route path="" element={<Orçamentos />} />
        <Route path="Gerar" element={<GerarOrçamento />} />
      </Route>
      <Route path="Documento" element={<OutletDocumento />}>
        <Route path=":id" element={<Documento />} />
        <Route path="Item/:hash" element={<DocumentoItem />} />
        <Route path="Garantia/:hash" element={<DocumentoGarantia />} />
        <Route path="Equipamento/:hash" element={<DocumentoEquipamento />} />
      </Route>
    </Routes>
  );
};

export default RoutesTree;
